import React, { useState } from "react";
import { connect } from "react-redux";
import AllExports from "../AllExportsImports";
import { ModalForAddEvents } from "../Modals/ModalForAddEvents";
import RemovePopup from "./removePopup/RemovePopup";
import IconBtn from "./IconBtn";

function EventsNewsComp(props) {
  const eventsData = props.eventsData;
  const [showModal, setShowModal] = useState(false);
  const [popup, setPopup] = useState({ open: false, delete: false });
  const [loader, setLoader] = useState(false);

  const removeEventsNews = async (uid, string, imageID) => {
    setLoader(true);
    try {
      let data = await AllExports.Api.FireBaseAPI.removeDeathNewsAndEventNews(
        uid,
        string,
        imageID
      );
      console.log(data);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  if (eventsData) {
    let array = [];
    for (let key in eventsData) {
      array.push(eventsData[key]);
    }
    let sorted = array.sort(function (a, b) {
      return a.timeStamp - b.timeStamp;
    });
    var display = sorted.reverse();
  }

  if (popup.open) {
    return <RemovePopup state={popup} setState={setPopup} />;
  }

  return (
    <div className="App">
      {showModal && (
        <ModalForAddEvents showModal={showModal} setShowModal={setShowModal} />
      )}
      <IconBtn setState={setShowModal} />
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      <div className="EventsNewsMain">
        {!!display.length &&
          display.map((e) => {
            return (
              <div key={e.timeStamp} className="Events">
                {e.createdBy && (
                  <div style={{ fontSize: "10px" }}>
                    <div>
                      <strong>Uploaded By</strong>
                    </div>
                    <div>Name: {e.createdBy.name}</div>
                    <div>Email: {e.createdBy.email}</div>{" "}
                  </div>
                )}
                <h6>{e.title}</h6>
                <p>ID: {e.timeStamp}</p>
                <button
                  onClick={() =>
                    setPopup({
                      open: true,
                      delete: () =>
                        removeEventsNews(
                          e.timeStamp,
                          "eventNews",
                          !!e.imageUrls
                            ? !!e.imageUrls.length
                              ? e.imageUrls[0].id
                              : ""
                            : ""
                        ),
                    })
                  }
                >
                  Remove
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    eventsData: state.eventsData,
    allFCMTokens: state.allFCMTokens,
  };
};
export default connect(mapStateToProps)(EventsNewsComp);
