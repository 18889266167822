import React, { useEffect } from "react";
import AllExports from "../AllExportsImports";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Toggle } from "rsuite";
import {} from "../Components";

function ButtonsFlag(props) {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.buttonsFlage) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  const changeFlags = async (name, flag) => {
    try {
      let done = await AllExports.Api.FireBaseAPI.ControllingFlags(name, flag);
      alert(done);
    } catch (e) {
      alert(e);
    }
  };

  const changeExtraBtns = async (name, flag) => {
    try {
      let done = await AllExports.Api.FireBaseAPI.ControllingExtraBtns(
        name,
        "visible",
        flag
      );
      alert(done);
    } catch (e) {
      alert(e);
    }
  };

  const renderButtonFlags = (flags, extraBtns) => {
    let array = [];
    for (var key in flags) {
      array.push({ name: key, flag: flags[key] });
    }

    for (let btn in extraBtns) {
      array.push({
        btn,
        name: `${extraBtns[btn].title} (${btn})`,
        flag: extraBtns[btn].visible,
      });
    }

    return array.map((e) => {
      return (
        <div
          key={Math.random()}
          style={{
            margin: "0px auto",
            width: 250,
            borderBottom: "1px solid white",
            padding: 10,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{e.name} : </span>
          <Toggle
            size="sm"
            checked={e.flag}
            onChange={() => {
              e.btn !== undefined
                ? changeExtraBtns(e.btn, !e.flag)
                : changeFlags(e.name, !e.flag);
            }}
          />
        </div>
      );
    });
  };

  return (
    !!user &&
    user.access.buttonsFlage && (
      <div>
        <div style={{ paddingTop: 30 }}>
          {renderButtonFlags(props.allFlags, props.extraBtns)}
        </div>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    allFlags: state.allFlags,
    extraBtns: state.extraBtns,
  };
};

export default connect(mapStateToProps)(ButtonsFlag);
