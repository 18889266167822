import React from "react";

const DataTable = ({ headCells, children }) => {
  return (
    <table className="dataTable">
      <thead>
        <tr>
          {headCells.map((ele, ind) => (
            <th key={ind}>{ele}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default DataTable;
