import React from "react";
import { Route, Routes } from "react-router-dom";
import * as Screens from "../Screens";

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Screens.Dashboard />} />
      <Route path="/login" element={<Screens.Login />} />
      <Route path="/feedBack" element={<Screens.FeedBack />} />
      <Route path="/about_us" element={<Screens.AboutUs />} />
      <Route path="/death_news" element={<Screens.DeathNews />} />
      <Route path="/donate_us" element={<Screens.DonateUs />} />
      <Route path="/event_news" element={<Screens.EventsNews />} />
      <Route path="/main_page_banner" element={<Screens.MainPageBanner />} />
      <Route path="/popup_screen" element={<Screens.PopupScreen />} />
      <Route path="/quizes" element={<Screens.Quizes />} />
      <Route path="/telethon" element={<Screens.Telethon />} />
      <Route path="/user" element={<Screens.User />} />
      <Route path="/bottons_flag" element={<Screens.ButtonsFlag />} />
      <Route path="/ExtraBtns" element={<Screens.ExtraBtns />} />
      <Route
        path="/push_notifications"
        element={<Screens.PushNotifications />}
      />
      <Route
        path="/GeneralDataForApp"
        element={<Screens.GeneralDataForApp />}
      />
      <Route
        path="/generalDataForWebViews"
        element={<Screens.GeneralDataForWebViews />}
      />
      <Route
        path="/generalDataForAdminPanel"
        element={<Screens.GeneralDataForAdminPanel />}
      />
    </Routes>
  );
};

export default AppRoutes;
