import React, { useState } from "react";
import style from "./topic.module.css";
import { sendNotificationUsingTopic } from "../../../Functions/inAppFunctions";
import { Alert, Uploader } from "rsuite";
import AllExports from "../../../AllExportsImports";

const FCMTopic = ({ setLoader }) => {
  const [topic, setTopic] = useState("");
  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });
  const [data, setData] = useState({
    type: "",
    id: "",
  });
  const [image, setImage] = useState([]);

  const sendTopicMsg = async (e) => {
    e.preventDefault();
    setLoader(true);
    let obj = {
      notification,
      topic,
    };

    if (topic === "Death_Anniversaries") {
      obj = {
        ...obj,
        data: {
          type: "DeathNews",
          id: "DeathAnniversaries",
        },
      };
    } else {
      if (data.type !== "" && data.id !== "") {
        obj = { ...obj, data };
      } else if (data.type === "" || data.id === "") {
        Alert.warning("Please fullfill all the data fields");
        return setLoader(false);
      }
    }
    let imageUrl = "";
    if (image[0] !== undefined && image[0].blobFile !== undefined) {
      imageUrl = await AllExports.Api.FireBaseAPI.uploadImage(
        "notification/" + image[0].blobFile.lastModified,
        image[0].blobFile
      );
    }

    const response = await sendNotificationUsingTopic(obj, imageUrl);
    if (response.message === "success") {
      Alert.success("Notification Sent Successfully");
    } else {
      Alert.error("Notification Failed");
    }

    setLoader(false);
    setTopic("");
    setData({
      type: "",
      id: "",
    });
    setImage([]);
    setNotification({
      title: "",
      body: "",
    });
  };
  return (
    <main className={style.mainDiv}>
      <h3>Send Notifications</h3>
      <form onSubmit={sendTopicMsg}>
        <section>
          <select
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            className="selection"
            required
          >
            <option value="">Select Topic</option>
            <option value="All">All</option>
            <option value="Death_News">Death News</option>
            <option value="Death_Anniversaries">Death Anniversaries</option>
            <option value="Events">Events</option>
            <option value="testing">Testing</option>
          </select>
          <h5>Notification</h5>
          <input
            type="text"
            placeholder="Title"
            value={notification.title}
            required
            className="inputField"
            onChange={(e) =>
              setNotification({ ...notification, title: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Body"
            value={notification.body}
            required
            className="inputField"
            onChange={(e) =>
              setNotification({ ...notification, body: e.target.value })
            }
          />
          {topic !== "Death_Anniversaries" && (
            <>
              <h5>Data (optional)</h5>
              <select
                value={data.type}
                onChange={(e) => setData({ ...data, type: e.target.value })}
                className="selection"
              >
                <option value="">Select Type</option>
                <option value="DeathNews">Death News</option>
                <option value="NewsEvents">Events</option>
              </select>
              <input
                type="number"
                placeholder="ID"
                value={data.id}
                className="inputField"
                onChange={(e) => setData({ ...data, id: e.target.value })}
              />
            </>
          )}
          <button className="btn">Send</button>
        </section>
        <section>
          <Uploader
            accept={"files"}
            autoUpload={false}
            draggable
            fileList={image}
            onChange={(e) => setImage([e.length && e[e.length - 1]])}
          >
            <div
              style={{
                height: "352px",
                width: "230px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid grey",
              }}
            >
              <span>Click or Drag files to Upload</span>
            </div>
          </Uploader>
        </section>
      </form>
    </main>
  );
};

export default FCMTopic;
