import React, { useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { ModalForAddDonateUsText } from "../Modals/ModalForAddDonateUsText";
import style from "../Screens/extra-btns/extrabtns.module.css";
import BtnsEditModel from "./btnsEditModel/BtnsEditModel";
import IconBtn from "./IconBtn";

function DonateUsComp(props) {
  const donateUsData = props.donateUsData;
  const allFlags = props.allFlags;

  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState({
    btnName: "",
    visible: false,
  });
  const [editBtnData, setEditBtnData] = useState({
    label: "",
    webViewLink: "",
    style: {
      color: "",
      bgColor: "",
    },
    icon: {
      name: "",
      type: "",
    },
  });

  // const removeDonateUs = async () => {
  //   setLoader(true);
  //   try {
  //     await AllExports.Api.FireBaseAPI.removeAboutUsAndDonateUs("donateUs");
  //     setLoader(false);
  //     Alert.success("Donate Us Removed");
  //   } catch (e) {
  //     setLoader(false);
  //     Alert.warning(e.message);
  //   }
  // };

  if (editModal.visible) {
    return (
      <div className={style.donateDiv}>
        <BtnsEditModel
          editModal={editModal}
          setEditModal={setEditModal}
          editBtnData={editBtnData}
          setEditBtnData={setEditBtnData}
          donateUsData={donateUsData}
        />
      </div>
    );
  }

  return (
    <div className="App">
      <IconBtn setState={setShowModal} />
      {showModal && (
        <ModalForAddDonateUsText
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {donateUsData && donateUsData.createdBy && (
        <div style={{ fontSize: "10px" }}>
          <div>
            <strong>Uploaded By</strong>
          </div>
          <div>Name: {donateUsData.createdBy.name}</div>
          <div>Email: {donateUsData.createdBy.email}</div>{" "}
        </div>
      )}
      {donateUsData && (
        <>
          <div>{parse(donateUsData.html)}</div>
          <br />
          <div>
            {donateUsData.webViewLink !== "" ? (
              <div>
                {"Label: " + donateUsData.label}
                <br />
                {donateUsData.webViewLink}
              </div>
            ) : (
              "Label: " + donateUsData.label
            )}
          </div>
        </>
      )}
      <div className={style.donateBtn}>
        <h5>Donate Button Details</h5>
        <div className={style.bigButtons}>
          <div>
            <p>Title: {donateUsData.label}</p>
            <p>
              Url:{" "}
              <a href={donateUsData.webViewLink}>{donateUsData.webViewLink}</a>
            </p>
            <p>Background Color: {donateUsData.style.bgColor}</p>
            <p>Color: {donateUsData.style.color}</p>
            <p>Icon Name: {donateUsData.icon.name}</p>
            <p>Icon Type: {donateUsData.icon.type}</p>
            <p>Visibility: {allFlags.donateFlag.toString()}</p>
          </div>
          <button
            className={style.btn}
            onClick={() => {
              setEditBtnData({
                title: donateUsData.label,
                url: donateUsData.webViewLink,
                style: {
                  color: donateUsData.style.color,
                  bgColor: donateUsData.style.bgColor,
                },
                icon: {
                  name: donateUsData.icon.name,
                  type: donateUsData.icon.type,
                },
              });
              setEditModal({
                visible: true,
                btnName: "Donate Btn",
              });
            }}
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    donateUsData: state.donateUsData,
    allFlags: state.allFlags,
  };
};

export default connect(mapStateToProps)(DonateUsComp);
