import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBS-518SO4jG1g8dwPdy-Oqwya1iMYPlNA",
  authDomain: "kpsiajapp.firebaseapp.com",
  databaseURL: "https://kpsiajapp.firebaseio.com",
  projectId: "kpsiajapp",
  storageBucket: "kpsiajapp.appspot.com",
  messagingSenderId: "780690874879",
  appId: "1:780690874879:web:8ae95bf56ef24e745e07ca",
  measurementId: "G-PBNL2V0SQY",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const reference = ref;

export { database, auth, storage, reference };
