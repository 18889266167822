import React, { useState } from "react";
import { connect } from "react-redux";
import AllExports from "../../AllExportsImports";

function QuizesEdit(props) {
  const [enable, setEnable] = useState(true);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState("");
  let options = {
    language: "",
    english: ["A", "B", "C", "D", "E"],
    urdu: ["الف", "ب", "ج", "د", "ہ"],
  };
  const { quizes } = props;
  let data = [];
  if (quizes) {
    for (let key in quizes) {
      data.push(quizes[key]);
    }
  }

  const removeQuize = (uid, string) => {
    setLoader(true);
    alert("Please wait it might take sometime");
    setTimeout(async () => {
      try {
        let data = await AllExports.Api.FireBaseAPI.removeQuizeFromDataBase(
          uid
        );
        alert(data.message);
        setLoader(false);
      } catch (e) {
        console.log(e);
        setLoader(false);
      }
    }, 1000);
  };

  const disableEnableQuiz = async (uid, name, status) => {
    if (enable) {
      try {
        let data = await AllExports.Api.FireBaseAPI.toogleOnQuizEnableDisable(
          uid,
          name,
          status,
          false
        );
        console.log(data);
      } catch (e) {
        console.log(e);
      }
    } else {
      if (id === "") {
        alert("Please fullfill the inputfield");
      } else {
        let users = id.replaceAll(" ", "");
        users = users.split(",");

        try {
          let data = await AllExports.Api.FireBaseAPI.toogleOnQuizEnableDisable(
            uid,
            name,
            status,
            users
          );
          console.log(data);
        } catch (e) {
          console.log(e);
        }
      }
    }

    setId("");
    setEnable(true);
  };

  return (
    <div className="App">
      {loader ? (
        <AllExports.Helpers.Loader />
      ) : (
        <div className="DeathNewsMain">
          {!!data.length &&
            data.map((e) => {
              options = {
                ...options,
                language: e.language,
              };
              return (
                <div key={e.timeStamp} className="DeathNews">
                  <h3>{`Title: ${e.title ? e.title : ""}`}</h3>
                  <h6>Minimum Age: {e.age}</h6>
                  <br />
                  {!!e.questionsArray &&
                    !!e.questionsArray.length &&
                    e.questionsArray.map((item, index) => {
                      return (
                        <div key={index} >
                          <p>{`Q ${index + 1}) ${item.question}`}</p>
                          {!!item.option1 && item.option1.value && (
                            <p>
                              {`${
                                options.language === "english"
                                  ? `${options.english[0]}) ${item.option1.value}`
                                  : `${item.option1.value} (${options.urdu[0]}`
                              } / ${item.option1.isTrue ? "True" : "False"}`}
                            </p>
                          )}
                          {!!item.option2 && item.option2.value && (
                            <p>{`${
                              options.language === "english"
                                ? `${options.english[1]}) ${item.option2.value}`
                                : `${item.option2.value} (${options.urdu[1]}`
                            } / ${item.option2.isTrue ? "True" : "False"}`}</p>
                          )}
                          {!!item.option3 && item.option3.value && (
                            <p>{`${
                              options.language === "english"
                                ? `${options.english[2]}) ${item.option3.value}`
                                : `${item.option3.value} (${options.urdu[2]}`
                            } / ${item.option3.isTrue ? "True" : "False"}`}</p>
                          )}
                          {!!item.option4 && item.option4.value && (
                            <p>{`${
                              options.language === "english"
                                ? `${options.english[3]}) ${item.option4.value}`
                                : `${item.option4.value} (${options.urdu[3]}`
                            } / ${item.option4.isTrue ? "True" : "False"}`}</p>
                          )}
                          {!!item.option5 && item.option5.value && (
                            <p>{`${
                              options.language === "english"
                                ? `${options.english[4]}) ${item.option5.value}`
                                : `${item.option5.value} (${options.urdu[4]}`
                            } / ${item.option5.isTrue ? "True" : "False"}`}</p>
                          )}
                          {item.shuffle !== undefined && (
                            <>
                              <p>
                                Shuffle Question:{" "}
                                {item.shuffle.question.toString()}
                              </p>
                              <p>
                                Shuffle Answer: {item.shuffle.answer.toString()}
                              </p>
                            </>
                          )}
                          <br />
                        </div>
                      );
                    })}

                  {e.enable.status ? (
                    <></>
                  ) : (
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        checked={enable}
                        onChange={() => setEnable(enable ? false : true)}
                      />
                      <label>Enable for All</label>
                    </div>
                  )}
                  {enable ? (
                    <></>
                  ) : (
                    <div className="checkbox" style={{ marginBottom: "7px" }}>
                      <label>Enter User ID</label>
                      <br />
                      <input
                        type="text"
                        value={id}
                        placeholder="xxxxxx,xxxxxx"
                        onChange={(e) => setId(e.target.value)}
                      />
                    </div>
                  )}
                  <button
                    onClick={() =>
                      disableEnableQuiz(
                        e.timeStamp,
                        "ramzanQuizes",
                        !e.enable.status
                      )
                    }
                  >
                    {" "}
                    {e.enable.status ? "Disable Quiz" : "Enable Quiz"}{" "}
                  </button>
                  <br />
                  <br />
                  <button
                    onClick={() => removeQuize(e.timeStamp, "ramzanQuizes")}
                  >
                    {" "}
                    Remove Quiz{" "}
                  </button>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    quizes: state.quizes,
  };
};

export default connect(mapStateToProps)(QuizesEdit);
