import React from 'react'
import UserContext, { UserProvider, UserConsumer } from './userContext'

const UserFromContext = () => React.useContext(UserContext)

export {
    UserContext,
    UserProvider,
    UserConsumer,
    UserFromContext
}