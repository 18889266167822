import React, { useState } from "react";
import { connect } from "react-redux";
import AllExports from "../../AllExportsImports";
import { Uploader, Icon, Alert } from "rsuite";
import BASE_URI from "../../core";
import axios from "axios";
import sendyEmailFunc from "./sendyEmailFunc";
import { getWebKey } from "../../Api/Firebase";

function DeathNewsAdd() {
  const user = AllExports.ContextApi.UserFromContext();
  let createdBy = {
    name: user.userInfo.name,
    email: user.userInfo.email,
  };

  const [wasOrToBe, setWasOrToBe] = useState("to be");
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [husbandName, setHusbandName] = useState("");
  const [age, setAge] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [dateOfNamaz, setDateOfNamaz] = useState("");
  const [timeOfNamaz, setTimeOfNamaz] = useState("");
  const [placeOfNamaz, setPlaceOfNamaz] = useState("");
  const [image, setImage] = useState([]);
  const [maleFemale, setMaleFemale] = useState("");
  const [burialInfo, setBurialInfo] = useState("");
  const [loader, setLoader] = useState(false);
  const [send, setSend] = useState({
    mail: true,
    notification: true,
    revise: false,
  });
  const burialInfoStarting = `Tadfeen (Burial) ${wasOrToBe} held at `;
  const burialInfoForWhatsApp =
    burialInfo !== "" ? `${burialInfoStarting}*${burialInfo}.*` : "";
  const burialInfoForApp =
    burialInfo !== "" ? burialInfoStarting + burialInfo + "." : "";
  let state = {
    createdBy,
    wasOrToBe,
    name,
    fatherName,
    husbandName,
    age,
    additionalInfo,
    dateOfNamaz,
    timeOfNamaz,
    placeOfNamaz,
    image,
    maleFemale,
    burialInfoForApp,
    burialInfoForWhatsApp,
    loader,
    revise: send.revise,
  };

  const handleDeathNewsRes = ({ notification, email, upload }) => {
    if (notification !== null && email !== null && upload !== null) {
      let uploadMsg = `Death News ${
        upload ? "Uploaded Successfully" : "Upload Failed"
      }`;
      let emailMsg = send.mail
        ? `\nEmail ${email ? "Sent Successfully" : "Failed"}`
        : "";
      let notificationMsg = send.notification
        ? `\nNotification ${notification ? "Sent Successfully" : "Failed"}`
        : "";

      setLoader(false);
      alert(uploadMsg + emailMsg + notificationMsg);
    }
  };

  const uploadDeathNews = async () => {
    let { notificationBody, obj } =
      await AllExports.Functions.InAppFunction.deathNewsUploadObj(state);

    try {
      const data = await AllExports.Api.FireBaseAPI.deathNewsAddFunc(
        obj,
        image
      );
      return { message: "success", notificationBody, ...data };
    } catch (error) {
      setLoader(false);
      Alert.error("Death News Upload Failed");
      console.log("Error Uploading Death News", error);
      return { message: "error" };
    }
  };

  const sendEmail = async (image, handleRes) => {
    try {
      const response = await AllExports.Api.FireBaseAPI.deathNewsEmailListID();
      await axios.post(
        `${BASE_URI}createCampaign`,
        await sendyEmailFunc(state, response.data, await getWebKey(), image)
      );
      handleRes.email = true;
    } catch (error) {
      handleRes.email = false;
    }
  };

  const sendDeathNewsNotification = async (uploadResponse, handleRes) => {
    let notification = {
      title: "Death News",
      body: uploadResponse.notificationBody,
    };
    const notificationRes =
      await AllExports.Functions.InAppFunction.sendNotificationUsingTopic(
        {
          topic: "Death_News",
          notification,
          data: { type: "DeathNews", id: uploadResponse.timeStamp.toString() },
        },
        uploadResponse.getImage ? uploadResponse.imageUrl : ""
      );
    if (notificationRes.message === "success") {
      handleRes.notification = true;
    } else {
      handleRes.notification = false;
    }
  };

  const deathNewsCreated = async () => {
    let handleRes = {
      upload: null,
      email: null,
      notification: null,
    };

    setLoader(true);

    // Upload Death News
    const uploadResponse = await uploadDeathNews();

    if (uploadResponse.message === "success") {
      handleRes.upload = true;
      if (send.mail) {
        // Send Email
        await sendEmail(
          uploadResponse.getImage ? uploadResponse.imageUrl : [],
          handleRes
        );
      } else {
        handleRes.email = false;
      }

      if (send.notification) {
        // Send Notification
        await sendDeathNewsNotification(uploadResponse, handleRes);
      } else {
        handleRes.notification = false;
      }
    }
    handleDeathNewsRes(handleRes);

    setWasOrToBe("to be");
    setName("");
    setFatherName("");
    setHusbandName("");
    setAge("");
    setDateOfNamaz("");
    setTimeOfNamaz("");
    setPlaceOfNamaz("");
    setImage([]);
    setMaleFemale("");
    setBurialInfo("");
  };

  return (
    <div className="DeathNewsAddMain">
      <div className="DeathNewsAddSubMain">
        {loader ? <AllExports.Helpers.Loader /> : <></>}
        <select
          value={maleFemale}
          onChange={(e) => {
            setMaleFemale(e.target.value);
          }}
        >
          <option value=""> Select Gender</option>
          <option value={"male"}>Male</option>
          <option value={"female"}>Female</option>
        </select>
        <br />
        <Uploader
          listType="picture"
          accept={"image"}
          multiple={false}
          autoUpload={false}
          fileList={image}
          onChange={(e) => setImage([e.length && e[e.length - 1]])}
        >
          <button>
            <Icon icon="camera-retro" size="lg" />
          </button>
        </Uploader>
        <br />
        <input
          placeholder={"Name"}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <br />
        <input
          placeholder={"Father Name"}
          value={fatherName}
          onChange={(e) => {
            setFatherName(e.target.value);
          }}
        />
        {maleFemale === "female" && (
          <div>
            {" "}
            <input
              placeholder={"Hasband Name"}
              value={husbandName}
              onChange={(e) => {
                setHusbandName(e.target.value);
              }}
            />{" "}
          </div>
        )}
        <br />
        <input
          placeholder={"age"}
          type={"number"}
          value={age}
          onChange={(e) => {
            setAge(e.target.value);
          }}
        />
        <div className="my-2">
          <label>Additional Information:</label>
          <br />
          <input
            type="text"
            placeholder="Additional Information"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />
        </div>
        <div>{burialInfoStarting}</div>
        <select
          value={burialInfo}
          onChange={(e) => {
            setBurialInfo(e.target.value);
          }}
        >
          <option value="">Select Place</option>
          {AllExports.Config.AppConfigData.BurialPlacesArry.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </select>
        <br />
        <input
          placeholder={"Burial Information"}
          value={burialInfo}
          onChange={(e) => {
            setBurialInfo(e.target.value);
          }}
        />
        <div>Date of Namaz e janaza</div>
        <input
          placeholder={"date of namaz"}
          type={"date"}
          value={dateOfNamaz}
          onChange={(e) => {
            setDateOfNamaz(e.target.value);
          }}
        />
        <div>select place of namaz e janaza</div>
        <select
          value={placeOfNamaz}
          onChange={(e) => {
            setPlaceOfNamaz(e.target.value);
          }}
        >
          <option value="">Select Place Of Namaz e Janaza</option>
          {AllExports.Config.AppConfigData.placeOfNamazArray.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </select>
        <br />
        <input
          type="text"
          placeholder={"Place of Namaz"}
          value={placeOfNamaz}
          onChange={(e) => {
            setPlaceOfNamaz(e.target.value);
          }}
        />
        <div>select was or to be</div>
        <select
          value={wasOrToBe}
          onChange={(e) => {
            setWasOrToBe(e.target.value);
          }}
        >
          <option value="to be">to be</option>
          <option value="was">was</option>
        </select>
        <div>select time of namaz e janaza</div>
        <select
          value={timeOfNamaz}
          onChange={(e) => {
            setTimeOfNamaz(e.target.value);
          }}
        >
          <option value="">Select Time Of Namaz e Janaza</option>
          {AllExports.Config.AppConfigData.timeOfNamazArray.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </select>
        <br />
        <input type="time" onChange={(e) => setTimeOfNamaz(e.target.value)} />
        <br />
        <input
          type="text"
          disabled
          placeholder={"Time of Namaz"}
          value={timeOfNamaz}
        />
        <br />
        <div className="checkbox">
          <input
            type="checkbox"
            checked={send.mail}
            onChange={() => setSend({ ...send, mail: !send.mail })}
          />
          <label>Send Email</label>
        </div>
        <div className="checkbox">
          <input
            type="checkbox"
            checked={send.notification}
            onChange={() =>
              setSend({ ...send, notification: !send.notification })
            }
          />
          <label>Send Notification</label>
        </div>
        <div className="checkbox">
          <input
            type="checkbox"
            checked={send.revise}
            onChange={() => setSend({ ...send, revise: !send.revise })}
          />
          <label>Revise</label>
        </div>
        <button onClick={deathNewsCreated}>Upload</button>
      </div>
      <div style={{ flex: 1 }}>
        <h3>Generated Text For Whatsapp</h3>
        <AllExports.Helpers.WhatsappText dataObj={state} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allFCMTokens: state.allFCMTokens,
  };
};

export default connect(mapStateToProps)(DeathNewsAdd);
