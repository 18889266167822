import React, { useEffect, useState } from "react";
import AllExports from "../../AllExportsImports";
import { FCMToken, FCMTopic, SubscribedDevices } from "../../Components";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import style from "./push.module.css";

const PushNotifications = (props) => {
  const user = AllExports.ContextApi.UserFromContext();
  const [loader, setLoader] = useState(false);
  const [msgType, setMsgType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.pushNotification) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  if (!!user && user.access.pushNotification) {
    return (
      <div>
        {loader ? <AllExports.Helpers.Loader /> : <></>}
        <main className={style.main}>
          <select
            value={msgType}
            onChange={(e) => setMsgType(e.target.value)}
            className="selection"
          >
            <option value="''">Select Type</option>
            <option value="topic">Topic</option>
            <option value="token">Token</option>
            <option value="devices">Subscribed Devices</option>
          </select>
          {msgType === "topic" && <FCMTopic setLoader={setLoader} />}
          {msgType === "token" && (
            <FCMToken setLoader={setLoader} allFCMTokens={props.allFCMTokens} />
          )}
          {msgType === "devices" && (
            <SubscribedDevices FCMTopics={props.FCMTopics} />
          )}
        </main>
      </div>
    );
  } else {
    return <div></div>;
  }
};

const mapStateToProps = (state) => {
  return {
    allFCMTokens: state.allFCMTokens,
    FCMTopics: state.FCMTopics,
  };
};

export default connect(mapStateToProps)(PushNotifications);
