import React, { useEffect } from "react";
import AllExports from "../AllExportsImports";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {} from "../Components";

function GeneralDataForAdminPanel(props) {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.pushNotification) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  return !!user && user.access.generalDataForAdminPanel && <div></div>;
}

const mapStateToProps = (state) => {
  return {
    allFCMTokens: state.allFCMTokens,
  };
};

export default connect(mapStateToProps)(GeneralDataForAdminPanel);
