import React, { useState } from "react";
import { Modal, Button, Alert } from "rsuite";
import AllExports from "../AllExportsImports";
import RichTextEditor from "react-rte";
import { getFinalHtml } from "../Functions/inAppFunctions";

export const ModalForAddAboutUsText = ({ showModal, setShowModal }) => {
  const user = AllExports.ContextApi.UserFromContext();
  let createdBy = {
    name: user.userInfo.name,
    email: user.userInfo.email,
  };
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [loader, setLoader] = useState(false);
  const uploadData = async () => {
    setLoader(true);
    let finalHtml = getFinalHtml(value);

    try {
      await AllExports.Api.FireBaseAPI.aboutUsAddFunc(finalHtml, createdBy);
      setLoader(false);
      Alert.success("About Us Text Updated");
    } catch (e) {
      setLoader(false);
      Alert.error(e.message);
    }
  };
  return (
    <Modal backdrop={true} show={showModal} onHide={() => setShowModal(false)}>
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center" }}>
          <span>Add AboutUs Text</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: "normal" }}>
        <RichTextEditor value={value} onChange={(value) => setValue(value)} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => uploadData()} appearance="primary">
          Upload
        </Button>
        <Button onClick={() => setShowModal(false)} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
