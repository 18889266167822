import React, { useState } from "react";
import { Modal, Button, Alert, Uploader, Icon } from "rsuite";
import AllExports from "../AllExportsImports";
import RichTextEditor from "react-rte";
import {
  getEmailListId,
  getFinalHtml,
  isNotNullOrEmpty,
  sendNotificationUsingTopic,
} from "../Functions/inAppFunctions";
import { eventEmailTemplate } from "../Components";

const sendObj = { notification: true, email: true };

export const ModalForAddEvents = ({ showModal, setShowModal }) => {
  const user = AllExports.ContextApi.UserFromContext();
  let createdBy = {
    name: user.userInfo.name,
    email: user.userInfo.email,
  };
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [send, setSend] = useState(sendObj);
  const [loader, setLoader] = useState(false);

  const validateInputs = () => {
    if (!isNotNullOrEmpty(title)) {
      Alert.warning("Event title is mandatory");
      return false;
    }

    if (
      send.notification &&
      (!isNotNullOrEmpty(notificationTitle) ||
        !isNotNullOrEmpty(notificationBody))
    ) {
      Alert.warning("Notification title and body are mandatory");
      return false;
    }

    return true;
  };

  const sendEventNotification = async (imageUrl, timestamp) => {
    let notification = {
      title: notificationTitle,
      body: notificationBody,
    };

    const notificationRes = await sendNotificationUsingTopic(
      {
        topic: "Events",
        notification,
        data: { type: "NewsEvents", id: timestamp },
      },
      imageUrl
    );

    return {
      message: `\nNotification Sent ${
        notificationRes.message === "success" ? "Successful" : "Failed"
      }`,
    };
  };

  const uploadEventOnFirebase = async (obj) => {
    try {
      const response = await AllExports.Api.FireBaseAPI.eventsNewsAddFunc(
        obj,
        files
      );
      return {
        status: "success",
        imageUrl: response.getImage ? response.imageUrl : "",
        timestamp: response.timeStamp.toString(),
        message: "Event Upload Successful",
      };
    } catch (error) {
      setLoader(false);
      alert(
        "Event Upload Failed\nNotification Failed\nCampaign Creation Failed"
      );
      return { status: "error" };
    }
  };

  const createEventCampaign = async (html) => {
    const credentials = {
      subject: `Event - ${title}`,
      name: "KPSIAJ Information Desk",
      email: "media@kpsiaj.org",
      reply: "media@kpsiaj.org",
      html,
    };

    const { status } = await getEmailListId("event", credentials);
    return {
      message: `\nCampaign Creation ${
        status === "success" ? "Successuful" : "Failed"
      }`,
    };
  };

  const resetState = () => {
    setShowModal(false);
    setFiles([]);
    setTitle("");
    setSend(sendObj);
    setNotificationTitle("");
    setNotificationBody("");
    setValue(RichTextEditor.createEmptyValue());
  };

  const publishEvent = async () => {
    const eventContent = getFinalHtml(value);
    let alertMsg = "";
    let obj = {
      title,
      text: eventContent,
      timeStamp: "",
      createdBy,
    };

    if (validateInputs()) {
      setLoader(true);

      // first step upload event image of firebase storage and event content on firebase database
      const uploadRes = await uploadEventOnFirebase(obj);

      if (uploadRes.status === "success") {
        alertMsg = uploadRes.message;

        if (send.notification) {
          const { message } = await sendEventNotification(
            uploadRes.imageUrl,
            uploadRes.timestamp
          );
          alertMsg += message;
        }

        if (send.email) {
          const { message } = await createEventCampaign(
            eventEmailTemplate(eventContent, uploadRes.imageUrl)
          );
          alertMsg += message;
        }

        alert(alertMsg);
        setLoader(false);
        resetState();
      }
    }
  };

  return (
    <Modal
      backdrop={true}
      show={showModal}
      onHide={() => setShowModal(false)}
      size="md"
    >
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center" }}>
          <span>Add Event</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: "normal" }}>
        <main className="[&>label]:block [&>label]:mt-2 [&>label]:text-black">
          <label className="!mt-0">Event Images:</label>
          <Uploader
            listType="picture"
            accept={"files"}
            multiple={false}
            autoUpload={false}
            fileList={files}
            onChange={(e) => setFiles([e.length && e[e.length - 1]])}
          >
            <button>
              <Icon icon="upload" size="lg" />
            </button>
          </Uploader>
          <label className="!mt-0">Event Title:</label>
          <input
            placeholder="Enter Event Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <label>Event Content:</label>
          <RichTextEditor
            value={value}
            placeholder="Enter Event Content"
            onChange={(value) => setValue(value)}
          />
          <hr />
          <label>Notification Title:</label>
          <input
            placeholder="Enter Notification Title"
            disabled={!send.notification}
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
          />
          <label>Notification Body:</label>
          <input
            placeholder="Enter Notification Body"
            disabled={!send.notification}
            value={notificationBody}
            onChange={(e) => setNotificationBody(e.target.value)}
          />
          <section className="mt-6">
            <div className="checkbox">
              <input
                type="checkbox"
                checked={send.notification}
                onChange={() =>
                  setSend({ ...send, notification: !send.notification })
                }
              />
              <label>Send Notification</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                checked={send.email}
                onChange={() => setSend({ ...send, email: !send.email })}
              />
              <label>Send Email</label>
            </div>
          </section>
        </main>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => publishEvent()} appearance="primary">
          Upload
        </Button>
        <Button onClick={() => setShowModal(false)} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
