import React, { useState } from "react";
import { connect } from "react-redux";
import AllExports from "../AllExportsImports";
import { Toggle, Panel, PanelGroup } from "rsuite";
import { ModalForAddUser } from "../Modals/ModalForAddUser";
import IconBtn from "./IconBtn";

function UserControllingComp(props) {
  const [showModal, setShowModal] = useState(false);
  let currentUser = AllExports.ContextApi.UserFromContext();
  let currentUserUid = currentUser.userInfo.uid;
  let restrictedUids = [
    "CQYuU2VvY7Nxr8QrQ1R5ZsRvlvC2",
    "Y18tCUgSGMRQExXTHmovlES0Ue53",
  ];
  restrictedUids.indexOf(currentUserUid) === -1 &&
    restrictedUids.push(currentUserUid);
  let allUsers = [];
  for (var key in props.adminUsers) {
    restrictedUids.indexOf(key) === -1 && allUsers.push(props.adminUsers[key]);
  }

  const getAccessList = (uid, d) => {
    console.log("Worked");

    let array = [];
    for (var key in d) {
      array.push({ name: key, access: d[key] });
    }
    return array.map((e) => (
      <div
        key={Math.random()}
        style={{
          borderBottom: "1px solid white",
          padding: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{e.name + ": "}</span>
        <Toggle
          size="sm"
          checked={e.access}
          onChange={() =>
            changingOnFirebaseAdminUsers(`/${uid}/access/${e.name}/`, !e.access)
          }
        />
      </div>
    ));
  };

  const changingOnFirebaseAdminUsers = async (way, change) => {
    try {
      let done = await AllExports.Api.FireBaseAPI.ControllingUser(way, change);
      alert(done);
    } catch (e) {
      alert(e);
    }
  };
  return (
    <div>
      {showModal && (
        <ModalForAddUser
          showModal={showModal}
          setShowModal={setShowModal}
          currentUser={currentUser.userInfo}
        />
      )}
      <IconBtn setState={setShowModal} />
      <PanelGroup
        accordion
        bordered
        style={{ width: "350px", margin: "10px auto" }}
      >
        {!!allUsers &&
          allUsers.length &&
          allUsers.map((e) => (
            <Panel
              key={e.userInfo.uid}
              header={e.userInfo.name}
              style={{ width: "350px" }}
            >
              <div>{e.userInfo.email}</div>
              <div
                style={{
                  borderBottom: "1px solid white",
                  padding: 10,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>is:</span>
                <Toggle
                  size="sm"
                  checked={e.isLogin}
                  onChange={() =>
                    changingOnFirebaseAdminUsers(
                      `/${e.userInfo.uid}/isLogin/`,
                      !e.isLogin
                    )
                  }
                />
              </div>
              <div>
                <strong>Access</strong>
              </div>
              {getAccessList(e.userInfo.uid, e.access)}
            </Panel>
          ))}
      </PanelGroup>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    adminUsers: state.adminUsers,
  };
};
export default connect(mapStateToProps)(UserControllingComp);
