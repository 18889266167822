import React, { useState } from "react";
// import { sendNotification } from "../../../Functions/inAppFunctions";

function FCMToken(props) {
  const tokens = props.allFCMTokens;
  let mainObj = {
    allFCMTokensForDeathNews: [],
    allFCMTokensForEventsNews: [],
    allFCMTokensForTestig: [],
    allFCMTokens: [],
  };
  for (var key in tokens) {
    mainObj.allFCMTokens.push(key);
    tokens[key].subscription &&
      tokens[key].subscription.deathNews &&
      mainObj.allFCMTokensForDeathNews.push(key);
    tokens[key].subscription &&
      tokens[key].subscription.newsEvents &&
      mainObj.allFCMTokensForEventsNews.push(key);
    tokens[key].subscription &&
      tokens[key].subscription.testing &&
      mainObj.allFCMTokensForTestig.push(key);
  }
  let mainArray = [];
  for (var key2 in mainObj) {
    mainArray.push({ name: key2, FMCTokens: mainObj[key2] });
  }

  const [imageUrl, setImageUrl] = useState("");
  const [catogery, setCatogery] = useState("");
  const [title, setTitle] = useState("");
  const [bodyText, setBodyText] = useState("");

  const formSubmit = async () => {
    props.setLoader(true);
    // let FCMTokens = [];
    // for (var key3 in mainObj) {
    //   if (key3 === catogery) {
    //     FCMTokens = mainObj[key3];
    //   }
    // }
    // let checkHTTPissueInLink = imageUrl.toString();
    // let checkifHttp = checkHTTPissueInLink.search("https");
    // let finalLink =
    //   checkifHttp !== 0
    //     ? checkHTTPissueInLink.replace(/http/g, "https")
    //     : checkHTTPissueInLink;
    // let image = imageUrl !== "" ? finalLink : "";
    // let notification = {
    //   title,
    //   body: bodyText,
    // };

    // let path;
    // switch (catogery) {
    //   case "allFCMTokensForDeathNews":
    //     path = "deathNews";
    //     break;
    //   case "allFCMTokensForEventsNews":
    //     path = "newsEvents";
    //     break;
    //   case "allFCMTokensForTestig":
    //     path = "testing";
    //     break;
    //   case "allFCMTokens":
    //     path = "all";
    //     break;
    //   default:
    //     break;
    // }

    // sendNotification(path, notification, props.setLoader, image);
  };

  return (
    <center>
      <h2>FCM Details</h2>
      <hr />
      <div>All FCM Registered : {mainObj.allFCMTokens.length}</div>
      <div>
        DeathNews FCM Registered : {mainObj.allFCMTokensForDeathNews.length}
      </div>
      <div>
        NewsEvents FCM Registered : {mainObj.allFCMTokensForEventsNews.length}
      </div>
      <div>
        Testing FCM Registered : {mainObj.allFCMTokensForTestig.length}{" "}
      </div>
      <h3>Sending Genral Notification to </h3>
      <select value={catogery} onChange={(e) => setCatogery(e.target.value)}>
        <option value="">Select</option>
        {mainArray.map((e) => (
          <option key={Math.random()} value={e.name}>
            {e.name}
          </option>
        ))}
      </select>
      <hr />
      {catogery !== "" && (
        <div>
          <h3>Now Send Notification to {catogery}</h3>
          {catogery}

          <div className="Form">
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              placeholder="Title For FMC"
              className="FormInputs"
            />
            <input
              value={bodyText}
              onChange={(e) => setBodyText(e.target.value)}
              type="email"
              placeholder="Body Text For FCM"
              className="FormInputs"
            />
            <input
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              type="email"
              placeholder="Image URL (optional)"
              className="FormInputs"
            />
            <br />
            <button onClick={formSubmit} disabled>
              Send
            </button>
          </div>
        </div>
      )}
    </center>
  );
}

export default FCMToken;
