import React, { useEffect } from "react";
import AllExports from "../AllExportsImports";
import { useNavigate } from "react-router-dom";
import { UserControllingComp } from "../Components";

export default function User() {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.userControll) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);
  return (
    !!user &&
    user.access.userControll && (
      <>
        <UserControllingComp />
      </>
    )
  );
}
