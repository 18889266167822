import React from "react";
import { Icon, IconButton } from "rsuite";

const style = {
  position: "fixed",
  bottom: 30,
  right: 20,
  backgroundColor: "var(--brown)",
};

const IconBtn = ({ setState }) => {
  return (
    <IconButton
      style={style}
      onClick={() => setState(true)}
      icon={<Icon icon="plus" style={{ color: "white" }} />}
      size="lg"
      circle
    />
  );
};

export default IconBtn;
