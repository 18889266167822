const reducer = (state = {}, action) => {
  switch (action.type) {
    case "USER-LOGIN": {
      return { ...state, user: action.user };
    }
    case "USER-LOGOUT": {
      return { ...state, user: action.user };
    }
    case "GET_ALL_USERS": {
      return { ...state, adminUsers: action.adminUsers };
    }
    case "GET_POPUP_SCREEN": {
      return { ...state, popupScreen: action.popupScreen };
    }
    case "GET_ABOUT_US_DATA": {
      return { ...state, aboutUsData: action.aboutUsData };
    }
    case "GET_TELETHON_FORMS": {
      return { ...state, telethonForms: action.telethonForms };
    }
    case "GET_DONATE_US_DATA": {
      return { ...state, donateUsData: action.donateUsData };
    }
    case "GET_DEATH_NEWS_DATA": {
      return { ...state, deathNewsData: action.deathNewsData };
    }
    case "GET_EVENT_NEWS_DATA": {
      return { ...state, eventsData: action.eventsData };
    }
    case "GET_MAIN_PAGE_BANNERS": {
      return { ...state, mainPageBanners: action.mainPageBanners };
    }
    case "GET_ALL_FCM_TOKENS": {
      return { ...state, allFCMTokens: action.allFCMTokens };
    }
    case "GET_FCM_TOPICS": {
      return { ...state, FCMTopics: action.FCMTopics };
    }
    case "GET_ALL_FEED_BACK": {
      return { ...state, feedbacks: action.feedbacks };
    }
    case "ALL_FLAGS": {
      return { ...state, allFlags: action.allFlags };
    }
    case "EXTRA_BTNS": {
      return { ...state, extraBtns: action.extraBtns };
    }
    case "CREATED_QUIZES": {
      return { ...state, quizes: action.quizes };
    }
    case "GET_QUIZ_USERS_AND_RESULTS": {
      return { ...state, quizUsersAndResults: action.quizUsersAndResults };
    }
    case "GET_PHONE_AUTH_USER_DATA": {
      return { ...state, users: action.users };
    }
    case "GET_GENERAL_DATA_FOR_WEBVIEWS": {
      return {
        ...state,
        generalDataForWebViews: action.generalDataForWebViews,
      };
    }
    case "GET_GENERAL_DATA_FOR_ADMINPANEL": {
      return {
        ...state,
        generalDataForAdminPanel: action.generalDataForAdminPanel,
      };
    }
    case "GET_GENERAL_DATA_FOR_APP": {
      return { ...state, generalDataForApp: action.generalDataForApp };
    }
    case "GET_GENERAL_IMAGES_DATA_FOR_APP": {
      return {
        ...state,
        generalImagesDataForApp: action.generalImagesDataForApp,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
