import React, { useState } from "react";
import style from "./btnsEditModel.module.css";
import AllExports from "../../AllExportsImports";
import { Loader } from "../../Helpers";

const BtnsEditModel = ({
  btns,
  setBtns,
  editBtnData,
  setEditBtnData,
  editModal,
  setEditModal,
  donateUsData,
}) => {
  const [loading, setLoading] = useState(false);

  const editBtn = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let done = await AllExports.Api.FireBaseAPI.ControllingExtraBtns(
        editModal.btnName,
        false,
        editBtnData
      );
      btns[+editModal.btnName[3] - 1] = editBtnData;
      setBtns([...btns]);
      alert(done);
      setLoading(false);
      setEditModal({
        visible: false,
        btnName: "",
      });
    } catch (e) {
      alert(e);
      setLoading(false);
    }
  };

  const editDonateBtn = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await AllExports.Api.FireBaseAPI.donateUsAddFunc({
        ...donateUsData,
        label: editBtnData.title,
        webViewLink: editBtnData.url,
        style: {
          color: editBtnData.style.color,
          bgColor: editBtnData.style.bgColor,
        },
        icon: { name: editBtnData.icon.name, type: editBtnData.icon.type },
      });
      alert("Changes Apply");
      setLoading(false);
      setEditModal({
        visible: false,
        btnName: "",
      });
    } catch (e) {
      alert(e);
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={style.modal}>
      <h4>Edit {editModal.btnName}</h4>
      <form
        onSubmit={(e) => {
          btns === undefined ? editDonateBtn(e) : editBtn(e);
        }}
      >
        <div>
          <label>Title:</label>
          <input
            type="text"
            maxLength={12}
            placeholder="Title"
            className={style.credentialField}
            value={editBtnData.title}
            onChange={(e) =>
              setEditBtnData({ ...editBtnData, title: e.target.value })
            }
          />
        </div>
        <div>
          <label>Url:</label>
          <input
            type="text"
            placeholder="Url"
            className={style.credentialField}
            value={editBtnData.url}
            onChange={(e) =>
              setEditBtnData({ ...editBtnData, url: e.target.value })
            }
          />
        </div>
        {/* {editBtnData.headers && ( */}
        <div>
          <label>Headers:</label>
          <div>
            <input
              type="text"
              placeholder="Headers"
              className={style.credentialField}
              value={editBtnData.headers}
              onChange={(e) =>
                setEditBtnData({
                  ...editBtnData,
                  headers: e.target.value,
                })
              }
            />
          </div>
        </div>
        {/* )} */}
        <div>
          <label>Icon Name:</label>
          <input
            type="text"
            placeholder="Icon Name"
            className={style.credentialField}
            value={editBtnData.icon.name}
            onChange={(e) =>
              setEditBtnData({
                ...editBtnData,
                icon: { ...editBtnData.icon, name: e.target.value },
              })
            }
          />
        </div>
        <div>
          <label>Icon Type:</label>
          <select
            name="iconType"
            defaultValue={editBtnData.icon.type}
            onChange={(e) =>
              setEditBtnData({
                ...editBtnData,
                icon: { ...editBtnData.icon, type: e.target.value },
              })
            }
          >
            <option value="MCIcons">MaterialCommunityIcons</option>
            <option value="MaterialIcons">MaterialIcons</option>
            <option value="AntDesign">AntDesign</option>
            <option value="FontAwesome">FontAwesome</option>
            <option value="FontAwesome6">FontAwesome6</option>
          </select>
        </div>
        <div>
          <label>Button Color:</label>
          <input
            type="text"
            placeholder="Button Color"
            className={style.credentialField}
            value={editBtnData.style.color}
            onChange={(e) =>
              setEditBtnData({
                ...editBtnData,
                style: { ...editBtnData.style, color: e.target.value },
              })
            }
          />
        </div>
        <div>
          <label>Button BgColor:</label>
          <input
            type="text"
            placeholder="Button BgColor"
            className={style.credentialField}
            value={editBtnData.style.bgColor}
            onChange={(e) =>
              setEditBtnData({
                ...editBtnData,
                style: { ...editBtnData.style, bgColor: e.target.value },
              })
            }
          />
        </div>
        <div>
          <button
            className={style.btn}
            onClick={() => setEditModal({ visible: false, btnName: "" })}
          >
            Cancel
          </button>
          <input type="submit" value="Save" className={style.btn} />
        </div>
      </form>
    </div>
  );
};

export default BtnsEditModel;
