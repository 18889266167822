import html from "./htmlCode";

const sendyEmailFunc = (state, id, key, image) => {
  const credentials = {
    subject: `Death News - ${state.name} ${state.revise ? "(Revised)" : ""}`,
    name: "KPSIAJ Information Desk",
    email: "media@kpsiaj.org",
    reply: "media@kpsiaj.org",
    html: "",
  };
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let tadfeen = state.burialInfoForApp;
  tadfeen = tadfeen.replace("Tadfeen (Burial) to be held at ", "");
  let time = state.timeOfNamaz;

  let userObj = {
    name_age: `<strong>${state.name}&nbsp;(${state.age} years)</strong>`,
    father_name: `<em>${
      state.maleFemale === "male" || state.maleFemale === ""
        ? "Son"
        : "Daughter"
    } of ${state.fatherName}${
      state.maleFemale === "male" || state.maleFemale === ""
        ? ""
        : `&nbsp; &amp;<br />
        Wife of&nbsp;${state.husbandName}`
    }</em>`,
    tadfeen: `<strong>${tadfeen}</strong>`,
    time: `<strong>${
      time[0] === "a"
        ? `after${time[6] === "N" ? "" : " Namaz e"}${time.replace(
            "after",
            ""
          )}`
        : time
    } at ${state.placeOfNamaz}</strong>`,
    date: `<span style="font-size:16px">Namaz-e-Janaza to be&nbsp;offered on ${
      days[new Date(state.dateOfNamaz).getDay()]
    }<u>, ${state.dateOfNamaz}</u>&nbsp;</span>`,
    image: `<br />
    <img
    alt=""
    class="mcnImage"
    src="${image}"
    style="
      max-width: 200px;
      max-height: 310px;
      border-radius: 1%;
      border: 0;
      height: auto;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
      vertical-align: bottom;
    "
    width="310"
    />
    <br />`,
  };

  let replace_name = html;
  replace_name = replace_name.replace(
    "<strong>Maher Bano&nbsp;(84 years)</strong>",
    userObj.name_age
  );
  replace_name = replace_name.replace(
    "<strong>Wadi e Zainab.</strong>",
    userObj.tadfeen
  );
  replace_name = replace_name.replace(
    "<strong>after Namaz e &nbsp;Zohrain at Mehfil-e-Shah-e-Khurasan</strong>",
    userObj.time
  );
  replace_name = replace_name.replace(
    `<em>helloworld</em>`,
    userObj.father_name
  );
  replace_name = replace_name.replace(
    `<span style="font-size:16px">Namaz-e-Janaza to be&nbsp;offered on Tuesday<u>, 14-Feb-2023</u>&nbsp;</span>`,
    userObj.date
  );
  replace_name = replace_name.replace(
    `<div>burialimage<div>`,
    image[0] !== undefined ? userObj.image : ""
  );

  let data = {
    ...credentials,
    html: replace_name,
    id,
    key,
  };

  return data;
};

export default sendyEmailFunc;
