import React, { useEffect, useState } from "react";
import AllExports from "../AllExportsImports";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Panel, PanelGroup } from "rsuite";
import {} from "../Components";

function FeedBack(props) {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();
  const [catogery, setCatogery] = useState("all");
  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.feedBack) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  if (props.feedbacks) {
    var data = [];
    var selectorArray = [];
    for (var key in props.feedbacks) {
      data.push(props.feedbacks[key]);
      selectorArray.push(props.feedbacks[key].departmentsForFeedBack);
    }
  }
  const sortingData = (d) => {
    let sorted = d.sort((a, b) => a.uid - b.uid);
    return sorted.reverse();
  };
  const renderTime = (param) => {
    let date = new Date(param);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };
  const sortByCatogery = (d) => {
    let newArray = [];
    for (var key in d) {
      if (d[key].departmentsForFeedBack === catogery) {
        newArray.push(d[key]);
      }
    }
    let sorted = newArray.sort((a, b) => a.uid - b.uid);
    return sorted.reverse();
  };
  let selectorArrayFiltred = [...new Set(selectorArray)];
  const feedbacksComp = (data) => {
    let final = catogery === "all" ? sortingData(data) : sortByCatogery(data);
    return (
      !!final &&
      final.length &&
      final.map((e, i) => (
        <Panel
          key={e.uid}
          header={`${e.departmentsForFeedBack}`}
          eventKey={i + 1}
        >
          <div>Time: {renderTime(e.uid)}</div>
          {e.email !== "" && <div>Eamil: {e.email}</div>}
          {e.fullName !== "" && <div>Name: {e.fullName}</div>}
          {e.fatherName !== "" && <div>F/H Name: {e.fatherName}</div>}
          {e.phoneNumber !== "" && <div>Number: {e.phoneNumber}</div>}
          <p style={{ textAlign: "left" }}>
            <strong>Feedback</strong> <br /> {e.feedbackText}
          </p>
        </Panel>
      ))
    );
  };
  return (
    !!user &&
    user.access.feedBack && (
      <div>
        <h2 className="FeedbackMainHeading">All Feedbacks</h2>
        <center>
          <span>Catogery : </span>
          <select
            value={catogery}
            onChange={(e) => {
              setCatogery(e.target.value);
            }}
          >
            <option value="all">All</option>
            {selectorArrayFiltred.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </select>
        </center>
        <PanelGroup
          accordion
          defaultActiveKey={1}
          bordered
          style={{ width: "90%", margin: "10px auto" }}
        >
          {feedbacksComp(data)}
        </PanelGroup>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    feedbacks: state.feedbacks,
  };
};
export default connect(mapStateToProps)(FeedBack);
