const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const timeOfNamazArray = [
  "after Fajar",
  "after Zohrain",
  "after Maghribain",
  "after Namaz e Jumma",
];

const placeOfNamazArray = [
  "Mehfil e Shah e Khurasan",
  "Fatimiyah Community Center",
  "Mehfil e Murtaza, PECHS",
  "Azakhana e Abu Talib, Soldier Bazar",
  "Masjid e Shah e Najaf, Martin Road",
];

const BurialPlacesArry = ["Wadi e Zainab(SA)"];

export { months, timeOfNamazArray, placeOfNamazArray, BurialPlacesArry };
