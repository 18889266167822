export const accessTrue = {
    aboutUs: true,
    buttonsFlage: true,
    pushNotification: true,
    deathNews: true,
    donateUs: true,
    events: true,
    mainPageBanner: true,
    popupScreen: true,
    quiz: true,
    telethon: true,
    userControll: true,
    feedBack: true,
    generalDataForApp: true,
    generalDataForWebViews: true,
    generalDataForAdminPanel: true,
}
export const accessFalse = {
    aboutUs: false,
    buttonsFlage: false,
    pushNotification: false,
    deathNews: false,
    donateUs: false,
    events: false,
    mainPageBanner: false,
    popupScreen: false,
    quiz: false,
    telethon: false,
    userControll: false,
    feedBack: false,
    generalDataForApp: false,
    generalDataForWebViews: false,
    generalDataForAdminPanel: false,
}
export const data = [{
    "label": "Admin",
    "value": 'isAdmin',
    "children": [
        {
            "label": "General Data App",
            "value": 'generalDataForApp'
        },
        {
            "label": "General Data WebViews",
            "value": 'generalDataForWebViews'
        },
        {
            "label": "General Data AdminPanel",
            "value": 'generalDataForAdminPanel'
        },
        {
            "label": "Bottons Flag",
            "value": 'buttonsFlage'
        },
        {
            "label": "Push Notifications",
            "value": 'pushNotification'
        }, {
            "label": "User Controll",
            "value": 'userControll'
        }, {
            "label": "FeedBack",
            "value": 'feedBack'
        }, {
            "label": "Telethon",
            "value": 'telethon'
        }, {
            "label": "Quiz",
            "value": 'quiz'
        }, {
            "label": "Popup Screen",
            "value": 'popupScreen'
        }, {
            "label": "Main Page Banner",
            "value": 'mainPageBanner'
        }, {
            "label": "Events",
            "value": 'events'
        }, {
            "label": "Donate Us",
            "value": 'donateUs'
        }, {
            "label": "Death News",
            "value": 'deathNews'
        }, {
            "label": "About Us",
            "value": 'aboutUs'
        },
    ]
},]