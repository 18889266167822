import { isNotNullOrEmpty } from "../Functions/inAppFunctions"

const eventEmailTemplate = (content, imageUrl) => {
  return `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
    <head><!-- NAME: ANNOUNCE --><!--[if gte mso 15]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
            <![endif]--><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1">
        <title>|MC:SUBJECT|</title>
        <style type="text/css">p{
                margin:10px 0;
                padding:0;
            }
            table{
                border-collapse:collapse;
            }
            h1,h2,h3,h4,h5,h6{
                display:block;
                margin:0;
                padding:0;
            }
            img,a img{
                border:0;
                height:auto;
                outline:none;
                text-decoration:none;
            }
            body,#bodyTable,#bodyCell{
                height:100%;
                margin:0;
                padding:0;
                width:100%;
            }
            .mcnPreviewText{
                display:none !important;
            }
            #outlook a{
                padding:0;
            }
            img{
                -ms-interpolation-mode:bicubic;
            }
            table{
                mso-table-lspace:0pt;
                mso-table-rspace:0pt;
            }
            .ReadMsgBody{
                width:100%;
            }
            .ExternalClass{
                width:100%;
            }
            p,a,li,td,blockquote{
                mso-line-height-rule:exactly;
            }
            a[href^=tel],a[href^=sms]{
                color:inherit;
                cursor:default;
                text-decoration:none;
            }
            p,a,li,td,body,table,blockquote{
                -ms-text-size-adjust:100%;
                -webkit-text-size-adjust:100%;
            }
            .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
                line-height:100%;
            }
            a[x-apple-data-detectors]{
                color:inherit !important;
                text-decoration:none !important;
                font-size:inherit !important;
                font-family:inherit !important;
                font-weight:inherit !important;
                line-height:inherit !important;
            }
            .templateContainer{
                max-width:600px !important;
            }
            a.mcnButton{
                display:block;
            }
            .mcnImage,.mcnRetinaImage{
                vertical-align:bottom;
            }
            .mcnTextContent{
                word-break:break-word;
            }
            .mcnTextContent img{
                height:auto !important;
            }
            .mcnDividerBlock{
                table-layout:fixed !important;
            }
            h1{
                color:#222222;
                font-family:Helvetica;
                font-size:40px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:center;
            }
            h2{
                color:#222222;
                font-family:Helvetica;
                font-size:34px;
                font-style:normal;
                font-weight:bold;
                line-height:150%;
                letter-spacing:normal;
                text-align:left;
            }
            h3{
                color:#444444;
                font-family:Helvetica;
                font-size:22px;
                font-style:normal;
                font-weight:bold;
                line-height:150%;
                letter-spacing:normal;
                text-align:left;
            }
            h4{
                color:#949494;
                font-family:Georgia;
                font-size:20px;
                font-style:italic;
                font-weight:normal;
                line-height:125%;
                letter-spacing:normal;
                text-align:center;
            }
            #templateHeader{
                background-color:#ffffff;
                background-image:none;
                background-repeat:no-repeat;
                background-position:50% 50%;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:1px;
                padding-bottom:1px;
            }
            .headerContainer{
                background-color:#transparent;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:0;
                padding-bottom:0;
            }
            .headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
                color:#757575;
                font-family:Helvetica;
                font-size:16px;
                line-height:150%;
                text-align:left;
            }
            .headerContainer .mcnTextContent a,.headerContainer .mcnTextContent p a{
                color:#007C89;
                font-weight:normal;
                text-decoration:underline;
            }
            #templateBody{
                background-color:#ffffff;
                background-image:none;
                background-repeat:no-repeat;
                background-position:50% 50%;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:11px;
                padding-bottom:11px;
            }
            .bodyContainer{
                background-color:#transparent;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:0;
                padding-bottom:0;
            }
            .bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
                color:#757575;
                font-family:Helvetica;
                font-size:16px;
                line-height:150%;
                text-align:left;
            }
            .bodyContainer .mcnTextContent a,.bodyContainer .mcnTextContent p a{
                color:#007C89;
                font-weight:normal;
                text-decoration:underline;
            }
            #templateFooter{
                background-color:#511918;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:0px;
                padding-bottom:0px;
            }
            .footerContainer{
                background-color:transparent;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:0;
                padding-bottom:0;
            }
            .footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
                color:#FFFFFF;
                font-family:Helvetica;
                font-size:12px;
                line-height:150%;
                text-align:center;
            }
            .footerContainer .mcnTextContent a,.footerContainer .mcnTextContent p a{
                color:#FFFFFF;
                font-weight:normal;
                text-decoration:underline;
            }
        @media only screen and (min-width:768px){
            .templateContainer{
                width:600px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            body,table,td,p,a,li,blockquote{
                -webkit-text-size-adjust:none !important;
            }
    
    }	@media only screen and (max-width: 480px){
            body{
                width:100% !important;
                min-width:100% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnRetinaImage{
                max-width:100% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnImage{
                width:100% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                max-width:100% !important;
                width:100% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnBoxedTextContentContainer{
                min-width:100% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnImageGroupContent{
                padding:9px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
                padding-top:9px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
                padding-top:18px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnImageCardBottomImageContent{
                padding-bottom:9px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnImageGroupBlockInner{
                padding-top:0 !important;
                padding-bottom:0 !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnImageGroupBlockOuter{
                padding-top:9px !important;
                padding-bottom:9px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnTextContent,.mcnBoxedTextContentColumn{
                padding-right:18px !important;
                padding-left:18px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
                padding-right:18px !important;
                padding-bottom:0 !important;
                padding-left:18px !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcpreview-image-uploader{
                display:none !important;
                width:100% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            h1{
                font-size:30px !important;
                line-height:125% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            h2{
                font-size:26px !important;
                line-height:125% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            h3{
                font-size:20px !important;
                line-height:150% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            h4{
                font-size:18px !important;
                line-height:150% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
                font-size:16px !important;
                line-height:150% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
                font-size:16px !important;
                line-height:150% !important;
            }
    
    }	@media only screen and (max-width: 480px){
            .footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
            }
    
    }
        </style>
    </head>
    <body data-gr-ext-installed="" data-new-gr-c-s-check-loaded="14.1179.0" style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><!--|IF:MC_PREVIEW_TEXT|--><!--[if !gte mso 9]><!---->
    <p><!--<![endif]--><!--|END:IF|--></p>
    
    <center>
    <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" width="100%">
        <tbody>
            <tr>
                <td align="center" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" valign="top"><!-- BEGIN TEMPLATE // -->
                <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
                    <tbody>
                        <tr>
                            <td align="center" data-template-container="" id="templateHeader" style="background:#ffffff none no-repeat 50% 50%/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: 50% 50%;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 1px;padding-bottom: 1px;" valign="top"><!--[if (gte mso 9)|(IE)]>
                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                        <tr>
                                        <td align="center" valign="top" width="600" style="width:600px;">
                                        <![endif]--><img alt="" src="https://email.kpsiaj.org/uploads/1638448870.jpg" style="width: 495px; height: 154px;" /><!--[if (gte mso 9)|(IE)]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]--></td>
                        </tr>
                        <tr>
                            <td align="center" data-template-container="" id="templateBody" style="background:#ffffff none no-repeat 50% 50%/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: 50% 50%;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 11px;padding-bottom: 11px;" valign="top"><!--[if (gte mso 9)|(IE)]>
                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                        <tr>
                                        <td align="center" valign="top" width="600" style="width:600px;">
                                        <![endif]-->
                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;" width="100%">
                                <tbody>
                                    <tr>
                                        <td class="bodyContainer" style="background:#transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;" valign="top">
                                        ${isNotNullOrEmpty(imageUrl) ?  `<img src="${imageUrl}" style="width: 100%; max-height: 800px;" alt="KPSIAJ Event Image" />` : ""}
                                        ${content !== "<p><br /></p>" ? content : ""}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]--></td>
                        </tr>
                        <tr>
                            <td align="center" data-template-container="" id="templateFooter" style="background:#511918 none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #511918;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0px;padding-bottom: 0px;" valign="top"><!--[if (gte mso 9)|(IE)]>
                                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                        <tr>
                                        <td align="center" valign="top" width="600" style="width:600px;">
                                        <![endif]-->
                            <table align="center" border="0" cellpadding="0" cellspacing="0" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;" width="100%">
                                <tbody>
                                    <tr>
                                        <td class="footerContainer" style="background:transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;" valign="top">
                                        <table border="0" cellpadding="0" cellspacing="0" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
                                            <tbody class="mcnTextBlockOuter">
                                                <tr>
                                                    <td class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top"><!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]--><!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnTextContentContainer" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #FFFFFF;font-family: Helvetica;font-size: 12px;line-height: 150%;text-align: center;" valign="top">
                                                                <center>
                                                                <table style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="padding-top: 5px;line-height: 10px;font-size: 6px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://api.whatsapp.com/send?phone=923343913770" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;"><img align="absbottom" alt="WhatSapp" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/WhatsApp.png" style="border: 0px initial;display: block;height: auto;outline: none;text-decoration: none;margin: 0px;-ms-interpolation-mode: bicubic;" width="25px" /> </a></td>
                                                                            <td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.faceboo.com/kpsiajkarachi" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;" target="_blank"><img align="absbottom" alt="Facebook" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Fb.png" style="display: block;height: auto;outline: none;text-decoration: none;flot: left;border: 0;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
                                                                            <td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.twitter.com/kpsiajkarachi" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;"><img align="absbottom" alt="Twitter" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Twitter.png" style="border: 0px;display: block;height: auto;outline: none;text-decoration: none;margin: 0px;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
                                                                            <td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.youtube.com/channel/UCdft3Z35RG2U2VoJo7HHXIw" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;" target="_blank"><img align="absbottom" alt="Youtube" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Youtube.png" style="display: block;height: auto;outline: none;text-decoration: none;flot: left;border: 0;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
                                                                            <td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.kpsiaj.org" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;" target="_blank"><img align="absbottom" alt="website" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Web.png" style="display: block;height: auto;outline: none;text-decoration: none;flot: left;border: 0;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
    
                                                                <div><br />
                                                                <a href="https://play.google.com/store/apps/details?id=com.kpsiaj" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;" target="_blank"><img align="absbottom" alt="Android App" class="mcnImage" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Android.png" style="display: block;height: auto;outline: none;text-decoration: none;flot: left;width: 80px;border: 0;-ms-interpolation-mode: bicubic;vertical-align: bottom;" /></a></div>
    
                                                                <hr /><span class="mcnTextContent" style="color: white;font-family: tahoma,verdana,segoe,sans-serif;word-break: break-word;font-size: 12px;line-height: 150%;text-align: center;">Media &amp; Outreach Department, Khoja (Pirhai) Shia Isna Asheri Jamaat, Karachi<br />
                                                                <br />
                                                                Don&#39;t wish to receive email updates from KPSIAJ, you may click <a href=[unsubscribe]>here</a> to unsubscribe.</span></center>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]--></td>
                        </tr>
                    </tbody>
                </table>
                <!-- // END TEMPLATE --></td>
            </tr>
        </tbody>
    </table>
    </center>
    <grammarly-desktop-integration data-grammarly-shadow-root="true"></grammarly-desktop-integration></body>
    </html>`;
};

export default eventEmailTemplate