import React, { useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import AllExports from "../AllExportsImports";
import { ModalForAddPopupScreen } from "../Modals/ModalForAddPopupScreen";
import IconBtn from "./IconBtn";

function PopupScreenComp(props) {
  const popupScreen = props.popupScreen;
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const removePopupScreen = async () => {
    setLoader(true);
    try {
      const done = await AllExports.Api.FireBaseAPI.removePopUpAndBannerImage(
        "popupScreen"
      );
      console.log(done);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  return (
    <div className="App">
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      {showModal && (
        <ModalForAddPopupScreen
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <IconBtn setState={setShowModal} />
      {!!popupScreen && (
        <div className="PopupScreenMain">
          <img
            alt="popup screen"
            src={popupScreen.ImageUrl}
            className="PopupScreenImage"
          />
          <div>
            {popupScreen.createdBy && (
              <div style={{ fontSize: "10px" }}>
                <div>
                  <strong>Uploaded By</strong>
                </div>
                <div>Name: {popupScreen.createdBy.name}</div>
                <div>Email: {popupScreen.createdBy.email}</div>{" "}
              </div>
            )}
            <p>{"Background Color : " + popupScreen.color}</p>
            <br />
            <br />
            {!!popupScreen.label && "Label : " + popupScreen.label}
            <p>{!!popupScreen.link && "Link to open : " + popupScreen.link}</p>
            {!!popupScreen.html && parse(popupScreen.html)}
            <br />
            <br />
            <button onClick={() => removePopupScreen()}>
              Remove Popup Screen
            </button>
            <br />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    popupScreen: state.popupScreen,
  };
};
export default connect(mapStateToProps)(PopupScreenComp);
