import React, { useState } from 'react'
import { Modal, Button, CheckTree, Alert, InputGroup, Input } from 'rsuite';
import AllExports from '../AllExportsImports';
import { data, accessFalse, accessTrue } from './dataObjForAddUserCheckTree'

export const ModalForAddUser = ({ showModal, setShowModal, currentUser }) => {
    const [checkedData, setCheckedData] = useState([])
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [loader, setLoader] = useState(false)


    let obj = {
        userInfo: { email: !!email && email + '@kpsiaj.org', name },
        access: accessTrue,
        isLogin: false,
        isAdmin: true
    }
    const final = async () => {
        if (name !== '' && email !== '' && checkedData.length && password !== '') {
            try {
                await AllExports.Api.FireBaseAPI.signUp(obj, password);
                setName('')
                setEmail('')
                setPassword('')
                setCheckedData([])
                setLoader(false)
                Alert.success(`User ${obj.userInfo.name} created`)
            } catch (error) {
                setLoader(false)
                Alert.error(error.message)
            }
        } else {
            setLoader(false)
            Alert.error('Pleas fill all fields & select access atleast one')
        }
    }
    const loginButton = async () => {
        setLoader(true)
        if (checkedData.length && checkedData[0] === 'isAdmin') {
            final(obj)
        } else {
            for (var key in accessFalse) {
                if (checkedData.indexOf(key) !== -1) {
                    accessFalse[key] = true
                } else {
                    accessFalse[key] = false
                }
            }
            obj.access = accessFalse
            obj.isAdmin = false
            final(obj)
        }
    }
    return <Modal style={{backgroundColor: '#227093'}} backdrop={true} show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
            <Modal.Title style={{ textAlign: 'center' }}><span>Add User</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loader ? <AllExports.Helpers.Loader /> : <></>}
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <InputGroup style={{ width: 300, marginBottom: 10, fontWeight: 'bold' }}>
                    <Input type='text' value={name} onChange={(e) => setName(e)} placeholder='User Name' />
                </InputGroup>
                <InputGroup style={{ width: 300, marginBottom: 10, fontWeight: 'bold' }}>
                    <Input type='email' value={email} onChange={(e) => setEmail(e)} placeholder='Email' />
                    <InputGroup.Addon style={{ width: 100 }}>@kpsiaj.org</InputGroup.Addon>
                </InputGroup>
                <InputGroup style={{ width: 300, marginBottom: 10, fontWeight: 'bold' }}>
                    <Input type='text' value={password} onChange={(e) => setPassword(e)} placeholder='Password' />
                </InputGroup>
                <CheckTree value={checkedData} data={data} onChange={(e) => setCheckedData(e)} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => loginButton()} appearance="primary">
                Add
            </Button>
            <Button onClick={() => setShowModal(false)} appearance="subtle">
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}