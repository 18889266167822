import React, { useState, useEffect } from "react";
import AllExports from "../AllExportsImports";
import { IoCopy } from "react-icons/io5";

export default function WhatsappText(props) {
  const [data, setData] = useState("");

  useEffect(() => {
    AllExports.Functions.InAppFunction.whatsappTextData(props).then((e) => {
      setData(e);
    });
  }, [props]);

  return (
    <React.Fragment>
      <textarea rows={25} cols={50} disabled value={data}></textarea>
      <br />
      <button
        onClick={() => navigator.clipboard.writeText(data)}
        style={{
          padding: "7px 10px",
          backgroundColor: "var(--brown)",
          color: "var(--lightgray)",
          borderRadius: "5px",
        }}
      >
        <IoCopy /> Copy Text
      </button>
    </React.Fragment>
  );
}
