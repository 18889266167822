import React, { useState, useEffect } from "react";
import AllExports from "../AllExportsImports";
import { useNavigate } from "react-router-dom";
import { QuizesComp } from "../Components";

export default function Quizes() {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();
  const [add, setAdd] = useState(true);
  const [remove, setRemove] = useState(false);
  const [results, setResults] = useState(false);
  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.quiz) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  function changeStateScreen(param) {
    if (param === "add") {
      setAdd(true);
      setRemove(false);
      setResults(false);
    } else if (param === "remove") {
      setAdd(false);
      setRemove(true);
      setResults(false);
    } else if (param === "results") {
      setAdd(false);
      setRemove(false);
      setResults(true);
    } else {
      setAdd(true);
      setRemove(false);
      setResults(false);
    }
  }
  return (
    !!user &&
    user.access.quiz && (
      <div>
        <center>
          <AllExports.Helpers.Buttons.AddRemoveButton
            func={() => changeStateScreen("add")}
            param={"Add Quiz"}
          />
          <AllExports.Helpers.Buttons.AddRemoveButton
            func={() => changeStateScreen("remove")}
            param={"Remove Quiz"}
          />
          <AllExports.Helpers.Buttons.AddRemoveButton
            func={() => changeStateScreen("results")}
            param={"Results Summary"}
          />
        </center>
        {add && <QuizesComp.QuizesAdd />}
        {remove && <QuizesComp.QuizesEdit />}
        {results && <QuizesComp.QuizesResults />}
      </div>
    )
  );
}
