import React, { useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import AllExports from "../AllExportsImports";
import { ModalForAddMainPageBanner } from "../Modals/ModalForAddMainPageBanner";
import IconBtn from "./IconBtn";

function MainPageBannerComp(props) {
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const mainPageBanners = props.mainPageBanners;

  const removeBannerScreen = async (timeStamp) => {
    setLoader(true);
    try {
      const done = await AllExports.Api.FireBaseAPI.removeBannerImage(
        timeStamp
      );
      console.log(done);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  if (mainPageBanners) {
    let array = [];
    for (var key in mainPageBanners) {
      array.push(mainPageBanners[key]);
    }
    let sorted = array.sort(function (a, b) {
      return a.timeStamp - b.timeStamp;
    });
    var display = sorted.reverse();
  }
  return (
    <div className="App">
      {showModal && (
        <ModalForAddMainPageBanner
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <IconBtn setState={setShowModal} />
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      {!!display &&
        !!display.length &&
        display.map((e) => {
          return (
            <div key={e.timeStamp} className="MainPageBannerMain">
              {e.createdBy && (
                <div style={{ fontSize: "10px" }}>
                  <div>
                    <strong>Uploaded By</strong>
                  </div>
                  <div>Name: {e.createdBy.name}</div>
                  <div>Email: {e.createdBy.email}</div>
                </div>
              )}
              <img
                alt="banner"
                src={e.ImageUrl}
                className="MainPageBannerImage"
              />
              <div>
                {!!e.label && "Label : " + e.label}
                {e.id && <p>ID: {e.id}</p>}
                {e.link && <p>{"Link to open : " + e.link}</p>}
                {e.html && e.html !== "<p><br></p>" && parse(e.html)}
                <br />
                <button onClick={() => removeBannerScreen(e.timeStamp)}>
                  Remove Banner
                </button>
                <br />
              </div>
            </div>
          );
        })}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    mainPageBanners: state.mainPageBanners,
  };
};
export default connect(mapStateToProps)(MainPageBannerComp);
