import React, { useEffect, useState } from "react";
import AllExports from "../../AllExportsImports";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconBtn } from "../../Components";
import { ModalForGeneralWebView } from "../../Modals";
import parse from "html-react-parser";
import style from "./style.module.css";
import { Alert } from "rsuite";
import { getFinalHtml } from "../../Functions/inAppFunctions";

function GeneralDataForWebViews({ generalDataForWebViews }) {
  const { text, imageUrl, createdBy, styles } =
    generalDataForWebViews.electionNews;
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.generalDataForWebViews) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  const uploadElectionNews = async (value, image, styles) => {
    if (image[0]?.blobFile === undefined) {
      return Alert.warning("Image is mandatory");
    } else if (styles.width === "" || styles.height === "") {
      return Alert.warning("Width and height are mandatory");
    }
    setLoading(true);

    let imageUrl = await AllExports.Api.FireBaseAPI.uploadImage(
      "electionNews/" + image[0].blobFile.lastModified,
      image[0].blobFile
    );

    const response = await AllExports.Api.FireBaseAPI.uploadData(
      {
        text: getFinalHtml(value),
        imageUrl,
        createdBy: {
          name: user.userInfo.name,
          emailAddress: user.userInfo.email,
        },
        styles,
      },
      "generalDataForWebViews/electionNews/"
    );

    setModal(false);
    setLoading(false);

    if (response.status === "Success") {
      Alert.success("Election News Upload Successful");
    } else {
      Alert.error("Election News Upload Fail");
      console.log(response.error);
    }
  };

  return (
    !!user &&
    user.access.generalDataForWebViews && (
      <main className={style.main}>
        <h3 className="heading">Election News</h3>
        <img
          src={imageUrl}
          alt="election-news-image"
          height={styles.height}
          width={styles.width}
        />
        <h5>
          Styles <br />
          Width: {styles.width} Height: {styles.height}
        </h5>
        {parse(text)}
        <p className={style.uploadDetails}>
          <strong>Uploaded By</strong>
          <br />
          Name: {createdBy.name}
          <br />
          Email Address: {createdBy.emailAddress}
        </p>
        <ModalForGeneralWebView
          modalProps={[modal, setModal]}
          onSubmitFunc={uploadElectionNews}
          text={text}
          loading={loading}
          styles={styles}
        />
        <IconBtn setState={setModal} />
      </main>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    generalDataForWebViews: state.generalDataForWebViews,
  };
};

export default connect(mapStateToProps)(GeneralDataForWebViews);
