import React, { useEffect, useState } from "react";
import style from "./sidebar.module.css";
import packageJson from "../../../../package.json";
import AllExports from "../../AllExportsImports";
import { useNavigate } from "react-router-dom";
import { GiGraveFlowers } from "react-icons/gi";
import { FaNewspaper, FaDonate, FaChevronLeft } from "react-icons/fa";
import {
  MdNotificationsActive,
  MdQuiz,
  MdFeedback,
  MdDashboard,
  MdAdminPanelSettings,
  MdScreenshot,
  MdSmartScreen,
} from "react-icons/md";
import { HiMiniUserGroup } from "react-icons/hi2";
import { BiSolidJoystickButton } from "react-icons/bi";
import { IoIosConstruct } from "react-icons/io";
import { SiTelegraph } from "react-icons/si";
import { BsFillFlagFill } from "react-icons/bs";

const Sidebar = () => {
  // const [collapse, setCollapse] = useState("collapse");
  const [display, setDisplay] = useState("flex");
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();
  const path = window.location.pathname;

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("Authentication Failed");
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [user, navigate]);

  if (!!user && user.isLogin) {
    return (
      <div className={`${style.mainDiv} ${display}`}>
        <main className={style.sidebar}>
          <section>
            <button
              onClick={() => navigate("/")}
              className={path === "/" ? style.active : ""}
            >
              <MdDashboard /> Dashboard
            </button>
            {user?.access?.deathNews && (
              <button
                onClick={() => navigate("/death_news")}
                className={path === "/death_news" ? style.active : ""}
              >
                <GiGraveFlowers /> Death News
              </button>
            )}
            {user.access.events && (
              <button
                onClick={() => navigate("/event_news")}
                className={path === "/event_news" ? style.active : ""}
              >
                <FaNewspaper /> Events
              </button>
            )}
            {user.access.donateUs && (
              <button
                onClick={() => navigate("/donate_us")}
                className={path === "/donate_us" ? style.active : ""}
              >
                <FaDonate /> Donate Us
              </button>
            )}
            {user.access.aboutUs && (
              <button
                onClick={() => navigate("/about_us")}
                className={path === "/about_us" ? style.active : ""}
              >
                <HiMiniUserGroup /> About Us
              </button>
            )}
            {user.access.mainPageBanner && (
              <button
                onClick={() => navigate("/main_page_banner")}
                className={path === "/main_page_banner" ? style.active : ""}
              >
                <MdSmartScreen /> Main Page Banner
              </button>
            )}
            {user.access.popupScreen && (
              <button
                onClick={() => navigate("/popup_screen")}
                className={path === "/popup_screen" ? style.active : ""}
              >
                <MdScreenshot /> Popup Screen
              </button>
            )}
            {user.access.quiz && (
              <button
                onClick={() => navigate("/quizes")}
                className={path === "/quizes" ? style.active : ""}
              >
                <MdQuiz /> Quizes
              </button>
            )}
            {user.access.telethon && (
              <button
                onClick={() => navigate("/telethon")}
                className={path === "/telethon" ? style.active : ""}
              >
                <SiTelegraph style={{ borderRadius: 7 }} /> Telethon
              </button>
            )}
            {user.access.feedBack && (
              <button
                onClick={() => navigate("/feedBack")}
                className={path === "/feedBack" ? style.active : ""}
              >
                <MdFeedback /> Feedback
              </button>
            )}
            {user.access.userControll && (
              <button
                onClick={() => navigate("/user")}
                className={path === "/user" ? style.active : ""}
              >
                <MdAdminPanelSettings /> User Control
              </button>
            )}
            {user.access.buttonsFlage && (
              <button
                onClick={() => navigate("/ExtraBtns")}
                className={path === "/ExtraBtns" ? style.active : ""}
              >
                <BiSolidJoystickButton /> Extra Btns
              </button>
            )}
            {user.access.buttonsFlage && (
              <button
                onClick={() => navigate("/bottons_flag")}
                className={path === "/bottons_flag" ? style.active : ""}
              >
                <BsFillFlagFill /> Buttons Flag
              </button>
            )}
            {user.access.pushNotification && (
              <button
                onClick={() => navigate("/push_notifications")}
                className={path === "/push_notifications" ? style.active : ""}
              >
                <MdNotificationsActive /> Notifications
              </button>
            )}
            {user.access.generalDataForApp && (
              <button
                onClick={() => navigate("/generalDataForApp")}
                className={path === "/generalDataForApp" ? style.active : ""}
              >
                <IoIosConstruct /> General App
              </button>
            )}
            {user.access.generalDataForWebViews && (
              <button
                onClick={() => navigate("/generalDataForWebViews")}
                className={
                  path === "/generalDataForWebViews" ? style.active : ""
                }
              >
                <IoIosConstruct /> General WebViews
              </button>
            )}
            {user.access.generalDataForAdminPanel && (
              <button
                onClick={() => navigate("/generalDataForAdminPanel")}
                className={
                  path === "/generalDataForAdminPanel" ? style.active : ""
                }
              >
                <IoIosConstruct /> General AdminPanel
              </button>
            )}
          </section>
          <section className={style.appConfigSec}>
            <h6>Version: {packageJson.version}</h6>
            <h6>Last Updated: {packageJson.lastUpdate}</h6>
          </section>
          {/* <footer className="mt-2 sticky bottom-0 bg-brown border-0 border-t border-white border-solid pt-2.5 flex justify-end">
            <button
              className="text-brown p-2 flex justify-center items-center bg-white rounded-full border-2 border-white border-solid transition-all hover:text-white hover:bg-transparent"
              onClick={() => setDisplay(display === "flex" ? "" : "hidden")}
            >
              <FaChevronLeft />
            </button>
          </footer> */}
        </main>
      </div>
    );
  }
};

export default Sidebar;
