import { onValue, ref } from "firebase/database";
import { database } from "../Config/firebase";

const setGeneralDataImagesForApp = () => {
  return (dispatch, getState) => {
    // database.ref
  };
};
export const setGeneralDataImagesForAppTelethone = () => {
  return (dispatch, getState) => {
    // ref(database, `generalDataForWebViews/telethone/imageGallery/${Date.now()}`).set("image")
    // database.ref
  };
};

export const getGeneralDataForWebViews = () => (dispatch) => {
  try {
    onValue(ref(database, "generalDataForWebViews"), (e) => {
      let generalDataForWebViews = e.val();
      dispatch({
        type: "GET_GENERAL_DATA_FOR_WEBVIEWS",
        generalDataForWebViews,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

const getTelethonForms = () => (dispatch) => {
  try {
    onValue(ref(database, "telethon/forms"), (e) => {
      let telethonForms = e.val();
      dispatch({
        type: "GET_TELETHON_FORMS",
        telethonForms,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGeneralDataForAdminPanel = () => (dispatch) => {
  try {
    onValue(ref(database, "generalDataForAdminPanel"), (e) => {
      let generalDataForAdminPanel = e.val();
      dispatch({
        type: "GET_GENERAL_DATA_FOR_ADMINPANEL",
        generalDataForAdminPanel,
      });
    });
  } catch (error) {
    console.log(error);
  }
};
export const getGeneralDataForApp = () => (dispatch) => {
  try {
    onValue(ref(database, "generalDataForApp"), (e) => {
      let generalDataForApp = e.val();
      dispatch({ type: "GET_GENERAL_DATA_FOR_APP", generalDataForApp });
    });
  } catch (error) {
    console.log(error);
  }
};
export const getGeneralImagesDataForApp = () => (dispatch) => {
  try {
    onValue(ref(database, "generalImagesDataForApp"), (e) => {
      let generalImagesDataForApp = e.val();
      dispatch({
        type: "GET_GENERAL_IMAGES_DATA_FOR_APP",
        generalImagesDataForApp,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

const getAllFeedBacks = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "feedback"), (e) => {
      let feedbacks = e.val();
      if (feedbacks) {
        dispatch({ type: "GET_ALL_FEED_BACK", feedbacks });
      } else {
        dispatch({ type: "GET_ALL_FEED_BACK", feedbacks: null });
      }
    });
  };
};
const getAllFCMTokens = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "FCMTokens"), (e) => {
      let allFCMTokens = e.val();
      if (allFCMTokens) {
        dispatch({ type: "GET_ALL_FCM_TOKENS", allFCMTokens });
      } else {
        dispatch({ type: "GET_ALL_FCM_TOKENS", allFCMTokens: null });
      }
    });
  };
};

const getFCMTopics = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "topicSubscriptions"), (e) => {
      let FCMTopics = e.val();
      if (FCMTopics) {
        dispatch({ type: "GET_FCM_TOPICS", FCMTopics });
      } else {
        dispatch({ type: "GET_FCM_TOPICS", FCMTopics: null });
      }
    });
  };
};

const getMainPageBanners = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "mainPageBanners"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({ type: "GET_MAIN_PAGE_BANNERS", mainPageBanners: data });
      } else {
        dispatch({ type: "GET_MAIN_PAGE_BANNERS", mainPageBanners: null });
      }
    });
  };
};

const getEventsData = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "eventNews"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({ type: "GET_EVENT_NEWS_DATA", eventsData: data });
      } else {
        dispatch({ type: "GET_EVENT_NEWS_DATA", eventsData: null });
      }
    });
  };
};
const getDeathNewsData = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "deathNews"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({ type: "GET_DEATH_NEWS_DATA", deathNewsData: data });
      } else {
        dispatch({ type: "GET_DEATH_NEWS_DATA", deathNewsData: data });
      }
    });
  };
};
const getDonateUsData = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "donateUs"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({ type: "GET_DONATE_US_DATA", donateUsData: data });
      } else {
        dispatch({ type: "GET_DONATE_US_DATA", donateUsData: null });
      }
    });
  };
};

const getAboutUsData = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "aboutUs"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({ type: "GET_ABOUT_US_DATA", aboutUsData: data });
      } else {
        dispatch({ type: "GET_ABOUT_US_DATA", aboutUsData: null });
      }
    });
  };
};
const getPopUpScreen = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "popupScreen"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({ type: "GET_POPUP_SCREEN", popupScreen: data });
      } else {
        dispatch({ type: "GET_POPUP_SCREEN", popupScreen: null });
      }
    });
  };
};

const userLogin = (user) => {
  return {
    type: "USER-LOGIN",
    user,
  };
};
const userLogout = () => {
  return {
    type: "USER-LOGOUT",
    user: null,
  };
};
const getAllUsers = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "adminUsers"), (e) => {
      let dta = e.val();
      if (dta) {
        dispatch({ type: "GET_ALL_USERS", adminUsers: dta });
      } else {
        dispatch({ type: "GET_ALL_USERS", adminUsers: null });
      }
    });
  };
};

const getQuizUsersAndResults = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "userData"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({
          type: "GET_QUIZ_USERS_AND_RESULTS",
          quizUsersAndResults: data,
        });
      } else {
        dispatch({
          type: "GET_QUIZ_USERS_AND_RESULTS",
          quizUsersAndResults: null,
        });
      }
    });
  };
};

const getPhoneAuthUserData = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "ramzanQuizUsersData"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({
          type: "GET_PHONE_AUTH_USER_DATA",
          users: data,
        });
      } else {
        dispatch({
          type: "GET_PHONE_AUTH_USER_DATA",
          users: null,
        });
      }
    });
  };
};

const getAllFlags = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "flags"), (e) => {
      let dta = e.val();
      if (dta) {
        dispatch({ type: "ALL_FLAGS", allFlags: dta });
      } else {
        dispatch({ type: "ALL_FLAGS", allFlags: null });
      }
    });
  };
};
const getExtraBtns = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "extraBtns"), (e) => {
      let data = e.val();
      if (data) {
        dispatch({ type: "EXTRA_BTNS", extraBtns: data });
      } else {
        dispatch({ type: "EXTRA_BTNS", extraBtns: null });
      }
    });
  };
};
const getCreatedQuizes = () => {
  return (dispatch, getState) => {
    onValue(ref(database, "ramzanQuizes"), (e) => {
      let dta = e.val();
      if (dta) {
        dispatch({ type: "CREATED_QUIZES", quizes: dta });
      } else {
        dispatch({ type: "CREATED_QUIZES", quizes: null });
      }
    });
  };
};
export {
  userLogin,
  userLogout,
  getAllUsers,
  getPopUpScreen,
  getDonateUsData,
  getAboutUsData,
  getTelethonForms,
  getDeathNewsData,
  getEventsData,
  getMainPageBanners,
  getFCMTopics,
  getAllFCMTokens,
  getAllFeedBacks,
  getAllFlags,
  getExtraBtns,
  getCreatedQuizes,
  getPhoneAuthUserData,
  getQuizUsersAndResults,
  setGeneralDataImagesForApp,
};
