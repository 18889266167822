import React, { useState, useEffect } from "react";
import AllExports from "../AllExportsImports";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, InputGroup, Input, Alert } from "rsuite";
import { kpsiajLogo } from "../Assets";

function Login(props) {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        props.userLogin(null);
      } else {
        navigate("/");
      }
    }
  }, [user, navigate, props]);

  const loginButton = async () => {
    setLoader(true);
    try {
      const userLogin = await AllExports.Api.FireBaseAPI.login(
        email + "@kpsiaj.org",
        password
      );
      Alert.success(`Successfuly login`);
      props.userLogin(userLogin);
    } catch (e) {
      setEmail("");
      setPassword("");
      setLoader(false);
      Alert.error(e.message);
    }
  };

  if (loader) {
    return <AllExports.Helpers.Loader />;
  }

  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        height: "90vh",
      }}
    >
      <img src={kpsiajLogo} alt="kpsiajLogo" className="LogoImage" />
      <h2 style={{ marginTop: "60px", marginBottom: "40px" }}>Login Form</h2>
      <InputGroup style={{ width: 300, marginBottom: 10 }}>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e)}
          placeholder="Email"
        />
        <InputGroup.Addon
          style={{ width: 100, color: "black", fontWeight: "bold" }}
        >
          @kpsiaj.org
        </InputGroup.Addon>
      </InputGroup>
      <InputGroup style={{ width: 300, marginBottom: 10 }}>
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e)}
          placeholder="Password"
        />
      </InputGroup>
      <InputGroup style={{ width: 300, marginBottom: 10 }}>
        <Button
          style={{ color: "black", borderColor: "black", fontWeight: "bold" }}
          onClick={() => loginButton()}
          appearance="ghost"
          block
        >
          Login
        </Button>
      </InputGroup>
    </div>
  );
}
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (user) => dispatch(AllExports.Redux.ReduxAction.userLogin(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
