import React, { useState } from "react";
import { Button, Icon, Modal, Uploader } from "rsuite";
import { Loader } from "../Helpers";
import RichTextEditor from "react-rte";
import style from "./modal.module.css";

const ModalForGeneralWebView = ({
  modalProps,
  onSubmitFunc,
  loading,
  text,
  styles,
}) => {
  const [value, setValue] = useState(
    RichTextEditor.createValueFromString(text, "html")
  );
  const [image, setImage] = useState([]);
  const [modal, setModal] = modalProps;
  const [styleInputs, setStyleInputs] = useState({
    width: styles.width,
    height: styles.height,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal size="lg" show={modal} onHide={() => setModal(false)}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center" }}>
          <span>Add Election News</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: "normal" }}>
        <Uploader
          listType="picture"
          accept="image/*"
          multiple={false}
          autoUpload={false}
          draggable={true}
          fileList={image}
          onChange={(e) => setImage([e.length && e[e.length - 1]])}
        >
          <button>
            <Icon icon="upload" size="lg" />
          </button>
        </Uploader>
        <div className={style.credentialField}>
          <label>Width:</label>
          <input
            type="text"
            placeholder="Width"
            className="inputField"
            required
            value={styleInputs.width}
            onChange={(e) =>
              setStyleInputs({ ...styleInputs, width: e.target.value })
            }
          />
        </div>
        <div className={style.credentialField}>
          <label>Height:</label>
          <input
            type="text"
            placeholder="Height"
            className="inputField"
            required
            value={styleInputs.height}
            onChange={(e) =>
              setStyleInputs({ ...styleInputs, height: e.target.value })
            }
          />
        </div>
        <RichTextEditor
          value={value}
          onChange={(value) => setValue(value)}
          editorStyle={{
            minHeight: "30vh",
            margin: "5px",
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => onSubmitFunc(value, image, styleInputs)}
          appearance="primary"
        >
          Upload
        </Button>
        <Button onClick={() => setModal(false)} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalForGeneralWebView;
