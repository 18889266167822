import React, { useEffect } from "react";
import style from "./dashboard.module.css";
import { useNavigate } from "react-router-dom";
import AllExports from "../../AllExportsImports";

export default function Dashboard() {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("Authentication Failed");
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [user, navigate]);
  return (
    <main className={style.dashboard}>
      <h1>Welcome!</h1>
    </main>
  );
}
