import React, { useState } from "react";
import { connect } from "react-redux";
import AllExports from "../../AllExportsImports";
import RemovePopup from "../removePopup/RemovePopup";

function DeathNewsEdit(props) {
  const deathNewsData = props.deathNewsData;
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState({ open: false, delete: false });

  const removeDeathNews = async (uid, string, imageID) => {
    setLoader(true);
    try {
      let data = await AllExports.Api.FireBaseAPI.removeDeathNewsAndEventNews(
        uid,
        string,
        imageID,
      );
      console.log(data);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  if (deathNewsData) {
    let makeNewArrayWithtimeStringForSorting = [];
    for (var key in deathNewsData) {
      makeNewArrayWithtimeStringForSorting.push(deathNewsData[key]);
    }
    let sorted = makeNewArrayWithtimeStringForSorting.sort(function (a, b) {
      return a.timeString - b.timeString;
    });
    var display = sorted.reverse();
  }

  if (popup.open) {
    return <RemovePopup state={popup} setState={setPopup} />;
  }

  return (
    <div className="App">
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      <div className="DeathNewsMain">
        {display &&
          display.map((e) => {
            return (
              <div key={e.timeStamp} className="DeathNews">
                {e.createdBy && (
                  <div style={{ fontSize: "10px" }}>
                    <div>
                      <strong>Uploaded By</strong>
                    </div>
                    <div>Name: {e.createdBy.name}</div>
                    <div>Email: {e.createdBy.email}</div>
                    <div>ID: {e.timeStamp}</div>
                  </div>
                )}
                <p>{e.name}</p>
                <p>{e.fatherName}</p>
                <p>{e.husbandName}</p>
                <p>{e.maleFemale}</p>
                <p>{e.dateOfNamaz}</p>
                <p>{e.timeOfNamaz}</p>
                <button
                  onClick={() =>
                    setPopup({
                      open: true,
                      delete: () =>
                        removeDeathNews(
                          e.timeStamp,
                          "deathNews",
                          e.imageID || "",
                        ),
                    })
                  }
                >
                  {" "}
                  Remove{" "}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    deathNewsData: state.deathNewsData,
  };
};

export default connect(mapStateToProps)(DeathNewsEdit);
