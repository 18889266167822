import React from "react";
import AllExports from "./App/AllExportsImports";
import Main from "./App/main/Main";
import "./App.css";
import "rsuite/dist/styles/rsuite-default.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Provider store={AllExports.Redux.ReduxStore.store}>
        <PersistGate
          loading={null}
          persistor={AllExports.Redux.ReduxStore.persistor}
        >
          <Main />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
