import AllExports from "../AllExportsImports";
import axios from "axios";
import BASE_URI from "../core";
import { getWebKey } from "../Api/Firebase";
import { child, get, onValue, ref } from "firebase/database";
import { database } from "../Config/firebase";

const letTimeString = (dateOfNamaz, timeOfNamaz) => {
  let time = "00:00";
  if (timeOfNamaz === "after Fajar") {
    time = "05:00";
  } else if (timeOfNamaz === "after Zohrain") {
    time = "13:00";
  } else if (timeOfNamaz === "after Maghribain") {
    time = "19:00";
  } else if (timeOfNamaz === "after Namaz e Jumma") {
    time = "14:00";
  } else {
    time = timeOfNamaz;
  }
  return new Date(dateOfNamaz + " " + time).getTime();
};
const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ":AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};
const formatedDate = (data, characters) => {
  let sdf = Date.parse(data);
  let date = new Date(sdf);
  let year = date.getFullYear();
  let getMnt = date.getMonth();
  let day = date.getDate();
  let month = AllExports.Config.AppConfigData.months[getMnt];
  month = characters ? month.slice(0, characters) : month;
  return `${day}-${month}-${year}`;
};

const convertEpochToDate = (epoch, characters) => {
  let date = new Date(epoch);
  let time = date.toLocaleTimeString();
  return `${formatedDate(date, characters)} ${time}`;
};

const deathNewsUploadObj = async (dataFromState) => {
  const {
    createdBy,
    name,
    fatherName,
    age,
    dateOfNamaz,
    timeOfNamaz,
    placeOfNamaz,
    maleFemale,
    husbandName,
    burialInfoForApp,
    wasOrToBe,
  } = dataFromState;
  let validTime = "";
  if (
    timeOfNamaz !== "after Fajar" ||
    timeOfNamaz !== "after Zohrain" ||
    timeOfNamaz !== "after Maghribain" ||
    timeOfNamaz !== "after Namaz e Jumma"
  ) {
    validTime = await tConvert(timeOfNamaz);
  } else {
    validTime = timeOfNamaz;
  }
  let timeString = await letTimeString(dateOfNamaz, timeOfNamaz);
  let clockTimeOfNamaz =
    timeOfNamaz.search("after") === -1 ? "at " + validTime : validTime;
  let dateOfNamazFormated = await formatedDate(dateOfNamaz);
  let notificationBody =
    "\n" +
    name +
    (maleFemale === "male" && fatherName !== ""
      ? " s/o " + fatherName
      : maleFemale === "female" && fatherName !== ""
      ? " d/o " + fatherName
      : "") +
    (maleFemale === "female" && husbandName !== ""
      ? " w/o " + husbandName
      : "") +
    (age !== "" ? `\nAge: ${age} years` : "") +
    ` \n\nNamaz-e-Janaza ${wasOrToBe} offered on ${dateOfNamazFormated} ${clockTimeOfNamaz} ${
      "at " + placeOfNamaz
    }`;
  let obj = {
    createdBy,
    wasOrToBe,
    nameString:
      "\n" +
      name +
      (maleFemale === "male" && fatherName !== ""
        ? " s/o " + fatherName
        : maleFemale === "female" && fatherName !== ""
        ? " d/o " + fatherName
        : "") +
      (maleFemale === "female" && husbandName !== ""
        ? " w/o " + husbandName
        : "") +
      (age !== "" ? `\n\nAge: ${age} years\n` : ""),
    dateTimeString: `\nNamaz-e-Janaza ${wasOrToBe} offered on ${dateOfNamazFormated} ${clockTimeOfNamaz} ${
      "at " + placeOfNamaz + ".\n"
    }`,
    name,
    fatherName,
    age,
    dateOfNamaz: dateOfNamazFormated,
    timeOfNamaz: clockTimeOfNamaz,
    placeOfNamaz: "at " + placeOfNamaz,
    timeStamp: "",
    timeString,
    maleFemale,
    husbandName: maleFemale === "female" ? " w/o " + husbandName : "",
    burialInfo: burialInfoForApp !== "" ? "\n" + burialInfoForApp + "\n" : "",
  };
  return await { notificationBody, obj };
};
const whatsappTextData = async (props) => {
  let headerText =
    "*إنَّا للهِ وَإِنَّا إِلَيْهِ رَاجِعُون*\n ہم اللہ کے لئے ہیں اور اسی کی بارگاہ میں واپس جانے والے ہیں\nTo Allah do we belong, and to Him do we return \n(Surah e Baqrah - 156)\n\nWith heartfelt condolence we inform you that our following member has passed away:\n";
  let nameString =
    "\n*" +
    props.dataObj.name +
    (props.dataObj.maleFemale === "male"
      ? " s/o " + props.dataObj.fatherName
      : props.dataObj.fatherName !== ""
      ? " d/o " + props.dataObj.fatherName
      : "") +
    (props.dataObj.maleFemale === "female"
      ? props.dataObj.husbandName !== ""
        ? " w/o " + props.dataObj.husbandName
        : ""
      : "") +
    "*";
  let burialInfo =
    props.dataObj.burialInfoForWhatsApp !== ""
      ? "\n\n" + props.dataObj.burialInfoForWhatsApp
      : "";
  let Age =
    props.dataObj.age !== "" ? `\n\nAge: *${props.dataObj.age} years*` : "";
  let dateOfNamazFormated = await formatedDate(props.dataObj.dateOfNamaz);
  let validTime = "";
  if (
    props.dataObj.timeOfNamaz !== "after Fajar" ||
    props.dataObj.timeOfNamaz !== "after Zohrain" ||
    props.dataObj.timeOfNamaz !== "after Maghribain" ||
    props.dataObj.timeOfNamaz !== "after Namaz e Jumma"
  ) {
    validTime = await tConvert(props.dataObj.timeOfNamaz);
  } else {
    validTime = props.dataObj.timeOfNamaz;
  }
  let clockTimeOfNamaz =
    props.dataObj.timeOfNamaz.search("after") === -1
      ? "at " + validTime
      : validTime;
  let dateTimeString = `\n\n*Namaz-e-Janaza ${
    props.dataObj.wasOrToBe
  } offered on ${dateOfNamazFormated} ${clockTimeOfNamaz} ${
    "at " + props.dataObj.placeOfNamaz + ".*"
  }`;
  let footerText = `\n\nIt is recommended to give alms (sadqa) for the deceased and offer Fateha & Namaz-e-Wahshat-e-Qabr. May Allah (subhanu wata'ala) grant deceased maghfirat & vicinity of Ahlulbayt (alaihis salaam) in Jannah. Ameen`; // return {obj}
  let { additionalInfo } = props.dataObj;
  let info = isNotNullOrEmpty(additionalInfo) ? `\n\n*${additionalInfo}*` : "";
  let revised = props.dataObj.revise ? `*REVISED*\n\n` : "";

  let data =
    revised +
    headerText +
    nameString +
    info +
    burialInfo +
    Age +
    dateTimeString +
    footerText;
  return data;
};

// const sendNotificationUsingTokens = async (obj, setLoader, image) => {
//   try {
//     const response = await axios.post(`${BASE_URI}sendNotification/token`, {
//       obj,
//       image,
//       key: await getWebKey(),
//     });
//     if (response.data.message === "Successfully sent message") {
//       Alert.success("Notification Sended Successfully");
//       setLoader(false);
//     }
//   } catch (e) {
//     console.log("Notification Failed", e);
//     setLoader(false);
//     Alert.error("Notification Failed");
//   }
// };

const getEmailListId = async (key, credentials) => {
  return await get(child(ref(database), `/emailListIds/${key}`))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        const id = snapshot.val();
        const response = await createCampaign(id, credentials);
        return response;
      } else {
        return { status: "error", message: "No data available" };
      }
    })
    .catch((error) => {
      return { status: "error", data: error };
    });
};

const createCampaign = async (id, credentials) => {
  try {
    await axios.post(`${BASE_URI}createCampaign`, {
      ...credentials,
      id,
      key: await getWebKey(),
    });
    return { status: "success" };
  } catch (error) {
    return { status: "error" };
  }
};

const sendNotificationUsingTopic = async (obj, image) => {
  try {
    await axios.post(`${BASE_URI}sendNotification/topic`, {
      obj,
      image,
      key: await getWebKey(),
    });
    return { message: "success" };
  } catch (e) {
    console.log("Notification Failed", e);
    return { message: "error" };
  }
};

const getFinalHtml = (value) => {
  let checkHTTPissueInHtml = value.toString("html");
  let checkifHttpInHtml = checkHTTPissueInHtml.search("https");
  return checkifHttpInHtml !== 0
    ? checkHTTPissueInHtml.replace(/http:/g, "https:")
    : checkHTTPissueInHtml;
};

const isNotNullOrEmpty = (variable) => {
  return variable !== "" && variable !== undefined && variable !== null;
};

export {
  convertEpochToDate,
  getEmailListId,
  isNotNullOrEmpty,
  letTimeString,
  tConvert,
  formatedDate,
  deathNewsUploadObj,
  whatsappTextData,
  sendNotificationUsingTopic,
  getFinalHtml,
};
