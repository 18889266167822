import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DataTable } from "../Components";

const Telethon = ({ telethonForms }) => {
  const [data, setData] = useState({
    dataArr: [],
    year: "all",
  });

  const handleForms = () => {
    const { dataArr } = data;
    dataArr.length = 0;
    for (var key in telethonForms) {
      for (var key2 in telethonForms[key]) {
        dataArr.push(telethonForms[key][key2]);
      }
    }
    setData({ ...data, dataArr });
  };

  useEffect(() => {
    handleForms();
  }, []);

  const tableBody = () => {
    const { dataArr, year } = data;
    if (dataArr.length) {
      let filteredArry = [];
      for (var key in dataArr) {
        let date = new Date(dataArr[key].uid).getFullYear().toString();
        year === "all"
          ? filteredArry.push(dataArr[key])
          : year === date && filteredArry.push(dataArr[key]);
      }

      let sortedArr = filteredArry.sort(function (a, b) {
        return a.uid - b.uid;
      });
      return sortedArr.map((e, i) => {
        return (
          <tr key={e.uid}>
            <td>{i + 1}</td>
            <td>
              {new Date(e.uid).getMonth().toString() +
                "/" +
                new Date(e.uid).getDate().toString() +
                "/" +
                new Date(e.uid).getFullYear().toString()}
            </td>
            <td>
              {new Date(e.uid).getHours().toString() +
                ":" +
                new Date(e.uid).getMinutes().toString() +
                ":" +
                new Date(e.uid).getSeconds().toString()}
            </td>
            <td className="username">{e.fullName + " " + e.fatherName}</td>
            <td>{e.currency.toUpperCase()}</td>
            <td>{e.amount}</td>
            <td style={{ whiteSpace: "nowrap" }}>{e.dedicatedFor}</td>
            <td className="username">{e.donationType}</td>
            <td>{"Android App"}</td>
            <td>{e.phoneNumber || e.phoneNoWithCode}</td>
            <td>{e.email}</td>
            <td>{e.countryName === "Pakistan" ? "Local" : "International"}</td>
            <td>{"Yes"}</td>
          </tr>
        );
      });
    }
  };

  const { dataArr } = data;
  let selectorArry = [];
  for (var key in dataArr) {
    selectorArry.push(new Date(dataArr[key].uid).getFullYear().toString());
  }
  let selectorArrayFiltred = [...new Set(selectorArry)];

  return (
    <main className="datatableWrapper">
      <h3 className="heading">Telethon Pledge</h3>
      <center>
        <span>Sort By Year : </span>
        {!!dataArr && !!dataArr.length && (
          <select
            value={data.year}
            onChange={(e) => setData({ ...data, year: e.target.value })}
          >
            <option value="all">All</option>
            {selectorArrayFiltred.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </select>
        )}
      </center>
      <DataTable
        headCells={[
          "S.NO",
          "Date",
          "Time",
          "Full Name",
          "Currency",
          "Amount",
          "Dedicated For",
          "Donation Type",
          "Mode Used For Receiving Pledge",
          "Phone Number",
          "Email Address",
          "Local / International",
          "Included In Balloting",
        ]}
      >
        {tableBody()}
      </DataTable>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    telethonForms: state.telethonForms,
  };
};

export default connect(mapStateToProps)(Telethon);
