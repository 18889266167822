import React from "react";
import AllExports from "../AllExportsImports";
import { connect } from "react-redux";
import "../Config/styleConfig.css";
import AppRoutes from "../routes/AppRoutes";
import { Header, Sidebar } from "../Components";
import style from "./main.module.css";

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      user: undefined,
    };
  }
  static getDerivedStateFromProps(nextProps) {
    let user = nextProps.user;
    let adminUsers = nextProps.adminUsers;
    if (user) {
      if (adminUsers) {
        let loginUser = undefined;
        for (var key in adminUsers) {
          if (user === key) {
            loginUser = adminUsers[key];
          }
        }
        return { user: loginUser };
      } else {
        return { user: undefined };
      }
    } else {
      return { user: undefined };
    }
  }
  componentDidMount() {
    this.props.getAllUsers();
    this.props.getQuizUsersAndResults();
    this.props.getPhoneAuthUserData();
    this.props.getAllFCMTokens();
    this.props.getTelethonForms();
    this.props.getFCMTopics();
    this.props.getDeathNewsData();
    this.props.getAboutUsData();
    this.props.getDonateUsData();
    this.props.getEventsData();
    this.props.getMainPageBanners();
    this.props.getPopUpScreen();
    this.props.getAllFeedBacks();
    this.props.getAllFlags();
    this.props.getExtraBtns();
    this.props.getCreatedQuizes();
    this.props.getGeneralImagesDataForApp();
    this.props.getGeneralDataForApp();
    this.props.getGeneralDataForAdminPanel();
    this.props.getGeneralDataForWebViews();
    this.props.setGeneralDataImagesForAppTelethone();
  }
  render() {
    return (
      <AllExports.ContextApi.UserProvider value={this.state.user}>
        {this.state.user ? (
          <>
            <Header />
            <main className={style.main}>
              <Sidebar />
              <div>
                <AppRoutes />
              </div>
            </main>
          </>
        ) : (
          <AppRoutes></AppRoutes>
        )}
      </AllExports.ContextApi.UserProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user, adminUsers: state.adminUsers };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: () => dispatch(AllExports.Redux.ReduxAction.getAllUsers()),
    getQuizUsersAndResults: () =>
      dispatch(AllExports.Redux.ReduxAction.getQuizUsersAndResults()),
    getPhoneAuthUserData: () =>
      dispatch(AllExports.Redux.ReduxAction.getPhoneAuthUserData()),
    getAllFCMTokens: () =>
      dispatch(AllExports.Redux.ReduxAction.getAllFCMTokens()),
    getTelethonForms: () =>
      dispatch(AllExports.Redux.ReduxAction.getTelethonForms()),
    getFCMTopics: () => dispatch(AllExports.Redux.ReduxAction.getFCMTopics()),
    getDeathNewsData: () =>
      dispatch(AllExports.Redux.ReduxAction.getDeathNewsData()),
    getAboutUsData: () =>
      dispatch(AllExports.Redux.ReduxAction.getAboutUsData()),
    getDonateUsData: () =>
      dispatch(AllExports.Redux.ReduxAction.getDonateUsData()),
    getEventsData: () => dispatch(AllExports.Redux.ReduxAction.getEventsData()),
    getMainPageBanners: () =>
      dispatch(AllExports.Redux.ReduxAction.getMainPageBanners()),
    getPopUpScreen: () =>
      dispatch(AllExports.Redux.ReduxAction.getPopUpScreen()),
    getAllFeedBacks: () =>
      dispatch(AllExports.Redux.ReduxAction.getAllFeedBacks()),
    getAllFlags: () => dispatch(AllExports.Redux.ReduxAction.getAllFlags()),
    getExtraBtns: () => dispatch(AllExports.Redux.ReduxAction.getExtraBtns()),
    getCreatedQuizes: () =>
      dispatch(AllExports.Redux.ReduxAction.getCreatedQuizes()),
    getGeneralDataForWebViews: () =>
      dispatch(AllExports.Redux.ReduxAction.getGeneralDataForWebViews()),
    getGeneralDataForAdminPanel: () =>
      dispatch(AllExports.Redux.ReduxAction.getGeneralDataForAdminPanel()),
    getGeneralDataForApp: () =>
      dispatch(AllExports.Redux.ReduxAction.getGeneralDataForApp()),
    getGeneralImagesDataForApp: () =>
      dispatch(AllExports.Redux.ReduxAction.getGeneralImagesDataForApp()),
    setGeneralDataImagesForAppTelethone: () =>
      dispatch(
        AllExports.Redux.ReduxAction.setGeneralDataImagesForAppTelethone()
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
