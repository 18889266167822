import React from "react";
import style from "./removePopup.module.css";
import { RxCrossCircled } from "react-icons/rx";

const removePopup = ({ state, setState }) => {
  return (
    <div className={style.mainDiv}>
      <div>
        <RxCrossCircled className={style.crossIcon} />
        <h3>Are you sure?</h3>
        <p>
          Do you really want to delete this record? This process cannot be
          undone
        </p>
        <div className={style.btnDiv}>
          <button onClick={() => setState({ open: false, delete: false })}>
            Cancel
          </button>
          <button
            onClick={() => {
              state.delete();
              setState({ open: false, delete: false });
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default removePopup;
