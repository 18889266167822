import React, { useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import AllExports from "../AllExportsImports";
import { Alert } from "rsuite";
import { ModalForAddAboutUsText } from "../Modals/ModalForAddAboutUsText";
import { RemovePopup } from ".";
import IconBtn from "./IconBtn";

function AboutUsComp(props) {
  const aboutUsData = props.aboutUsData;
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [popup, setPopup] = useState({ open: false, delete: false });

  const removeAboutUsData = async () => {
    setLoader(true);
    try {
      await AllExports.Api.FireBaseAPI.removeAboutUsAndDonateUs("aboutUs");
      setLoader(false);
      Alert.success("About Us Removed");
    } catch (e) {
      setLoader(false);
      Alert.warning(e.message);
    }
  };

  if (popup.open) {
    return <RemovePopup state={popup} setState={setPopup} />;
  }

  return (
    <div className="App">
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      <IconBtn setState={setShowModal} />
      {showModal && (
        <ModalForAddAboutUsText
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {aboutUsData && aboutUsData.createdBy && (
        <div style={{ fontSize: "10px" }}>
          <div>
            <strong>Uploaded By</strong>
          </div>
          <div>Name: {aboutUsData.createdBy.name}</div>
          <div>Email: {aboutUsData.createdBy.email}</div>{" "}
        </div>
      )}
      {aboutUsData && (
        <div>
          <div>{parse(aboutUsData.text)}</div>
          <br />
          <br />
          <button
            onClick={() =>
              setPopup({
                open: true,
                delete: () => removeAboutUsData(),
              })
            }
          >
            Remove About Us text
          </button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    aboutUsData: state.aboutUsData,
  };
};
export default connect(mapStateToProps)(AboutUsComp);
