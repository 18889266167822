import React, { useState } from "react";
import { Modal, Button, Alert, Uploader, Icon } from "rsuite";
import AllExports from "../AllExportsImports";
import RichTextEditor from "react-rte";

export const ModalForAddMainPageBanner = ({ showModal, setShowModal }) => {
  const user = AllExports.ContextApi.UserFromContext();
  let createdBy = {
    name: user.userInfo.name,
    email: user.userInfo.email,
  };
  const [files, setFiles] = useState([]);
  const [id, setId] = useState("");
  const [link, setLink] = useState("");
  const [loader, setLoader] = useState(false);
  const [extraAdd, setExtraAdd] = useState("");
  const [label, setLabel] = useState("");
  const [html, setHtml] = useState(RichTextEditor.createEmptyValue());

  const uploadImagesToFirebase = async () => {
    setLoader(true);
    let checkHTTPissueInHtml = html.toString("html");
    let checkifHttpInHtml = checkHTTPissueInHtml.search("https");
    let finalHtml =
      checkifHttpInHtml !== 0
        ? checkHTTPissueInHtml.replace(/http:/g, "https:")
        : checkHTTPissueInHtml;
    let checkHTTPissueInLink = link.toString();
    let checkifHttpInlink = checkHTTPissueInLink.search("https");
    let finalLink =
      checkifHttpInlink !== 0
        ? checkHTTPissueInLink.replace(/http:/g, "https:")
        : checkHTTPissueInLink;

    let obj = {
      isLink: extraAdd === "link" ? true : false,
      isHtml: extraAdd === "html" ? true : false,
      id,
      link: finalLink,
      html: finalHtml,
      label,
      createdBy,
    };
    try {
      await AllExports.Api.FireBaseAPI.uploadMainPageBanner(files, obj);
      setLoader(false);
      Alert.success("Banner Uploaded");
    } catch (e) {
      setLoader(false);
      Alert.error(e.message);
    }
  };
  const renderGetHTML = () => {
    return (
      <div>
        <p>Add HTML</p>
        <RichTextEditor value={html} onChange={(html) => setHtml(html)} />
      </div>
    );
  };

  return (
    <Modal backdrop={true} show={showModal} onHide={() => setShowModal(false)}>
      {loader ? <AllExports.Helpers.Loader /> : <></>}
      <Modal.Header>
        <Modal.Title style={{ textAlign: "center" }}>
          <span>Main Page Banner</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: "normal" }}>
        <Uploader
          listType="picture"
          accept={"image"}
          multiple={false}
          autoUpload={false}
          fileList={files}
          onChange={(e) => setFiles([e.length && e[e.length - 1]])}
        >
          <button>
            <Icon icon="camera-retro" size="lg" />
          </button>
        </Uploader>
        <br />
        <div>
          <span>Select Add HTML ot LINK : </span>
          <select
            value={extraAdd}
            onChange={(e) => setExtraAdd(e.target.value)}
          >
            <option value="">Select</option>
            <option value={"html"}>Add HTML</option>
            <option value={"link"}>Add Link</option>
            <option value={"id"}>Add ID</option>
          </select>
        </div>
        <br />
        {extraAdd !== "" && (
          <div>
            <p>Label For Header</p>
            <input
              type="url"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            {extraAdd === "link" && (
              <div>
                <p> Url open to click image</p>
                <input
                  type="url"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
            )}
            {extraAdd === "html" && renderGetHTML()}
            {extraAdd === "id" && (
              <div>
                <p>ID</p>
                <input
                  type="number"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                />
              </div>
            )}
            <br />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => uploadImagesToFirebase()} appearance="primary">
          Upload
        </Button>
        <Button onClick={() => setShowModal(false)} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
