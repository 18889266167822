import React from "react";
import AllExports from "../../AllExportsImports";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { kpsiajLogo, userIcn } from "../../Assets";
import { Dropdown } from "rsuite";
import { AiOutlineLogout } from "react-icons/ai";
import style from "./header.module.css";

const Header = (props) => {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();
  return (
    <header className={style.header}>
      <main>
        <div onClick={() => navigate("/")}>
          <img src={kpsiajLogo} alt="kpsiajLogo" />
          <div>
            <h3>KPSIAJ</h3>
            <h5>APP DASHBOARD</h5>
          </div>
        </div>
        {user?.userInfo?.name && (
          <Dropdown
            title={<h5>{user.userInfo.name}</h5>}
            icon={<img src={userIcn} alt="userIcn" width={20} />}
            className={style.dropdown}
          >
            <Dropdown.Item
              onSelect={() => props.userLogout()}
              icon={<AiOutlineLogout className={style.logoutIcn} />}
            >
              Logout
            </Dropdown.Item>
          </Dropdown>
        )}
      </main>
    </header>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    userLogout: () => dispatch(AllExports.Redux.ReduxAction.userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
