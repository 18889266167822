import React from "react";
import { Button } from "rsuite";

function AddRemoveButton(props) {
  const { func, param } = props;
  return (
    <button onClick={() => func()} className="AddRemoveButton">
      {param}
    </button>
  );
}

function DashboardButton(props) {
  const { func, param } = props;
  return (
    <Button className="dashboardBtn" onClick={() => func()}>
      {param}
    </Button>
  );
}
export { AddRemoveButton, DashboardButton };
