import React, { useState, useEffect } from "react";
import AllExports from "../AllExportsImports";
import { useNavigate } from "react-router-dom";
import { DeathNewsComp } from "../Components";

export default function DeathNews() {
  const user = AllExports.ContextApi.UserFromContext();
  const navigate = useNavigate();
  const [add, setAdd] = useState(true);

  useEffect(() => {
    if (user) {
      if (!user.isLogin) {
        alert("you are not login");
        navigate("/login");
      }
      if (!user.access.deathNews) {
        alert("sorry you are not access to this page");
        navigate("/");
      }
    } else {
      alert("you are not login");
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    !!user &&
    user.access.deathNews && (
      <div>
        <center>
          <button
            className="mr-2"
            onClick={() =>
              window.open("http://server3.kpsiaj.org:8084/index.aspx")
            }
          >
            {" "}
            Get Image & Data{" "}
          </button>
          <AllExports.Helpers.Buttons.AddRemoveButton
            func={() => setAdd(true)}
            param={"Add"}
          />
          <AllExports.Helpers.Buttons.AddRemoveButton
            func={() => setAdd(false)}
            param={"Remove"}
          />
        </center>
        {add ? <DeathNewsComp.DeathNewsAdd /> : <DeathNewsComp.DeathNewsEdit />}
      </div>
    )
  );
}
