import React, { Component } from "react";
import AllExports from "../../AllExportsImports";
import style from "./quiz.module.css";

class QuizesAdd extends Component {
  constructor() {
    super();
    this.state = {
      link: "",
      language: "english",
      title: "",
      question: "",
      option1: { value: "", isTrue: false },
      option2: { value: "", isTrue: false },
      option3: { value: "", isTrue: false },
      option4: { value: "", isTrue: false },
      option5: { value: "", isTrue: false },
      optionsArray: [],
      questionsArray: [],
      loader: false,
      age: 0,
      shuffle: { question: true, answer: true },
      english: ["A", "B", "C", "D", "E"],
      urdu: ["الف", "ب", "ج", "د", "ہ"],
      edit: null,
    };
  }
  deletFromArray(i) {
    let questionsArray = this.state.questionsArray;
    questionsArray.splice(i, 1);
    this.setState({ questionsArray });
  }
  sendDatatoFirebase = async () => {
    if (!this.state.title) {
      alert("please type quiz title first");
      return;
    }
    if (this.state.questionsArray.length) {
      this.setState({ loader: true });
      let obj = {
        enable: { status: false },
        questionsArray: this.state.questionsArray,
        title: this.state.title,
        link: this.state.link,
        language: this.state.language,
        age: this.state.age,
      };
      try {
        const done = await AllExports.Api.FireBaseAPI.uploadQuiz(obj);
        console.log(done);
        this.setState({
          loader: false,
          title: "",
          link: "",
          language: "english",
          age: 0,
          questionsArray: [],
        });
        alert("Quiz Uploaded");
      } catch (e) {
        console.log(e);
        this.setState({ loader: false });
      }
    }
  };
  addMoreQuestion() {
    const {
      question,
      option1,
      option2,
      option3,
      option4,
      option5,
      questionsArray,
      shuffle,
    } = this.state;
    questionsArray.push({
      question,
      option1,
      option2,
      option3,
      option4,
      option5,
      shuffle,
    });
    this.setState({
      questionsArray,
      question: "",
      option1: { value: "", isTrue: false },
      option2: { value: "", isTrue: false },
      option3: { value: "", isTrue: false },
      option4: { value: "", isTrue: false },
      option5: { value: "", isTrue: false },
      shuffle: { question: true, answer: true },
    });
  }
  editQuestion() {
    const {
      question,
      option1,
      option2,
      option3,
      option4,
      option5,
      questionsArray,
      shuffle,
      edit,
    } = this.state;
    questionsArray.splice(edit, 1, {
      question,
      option1,
      option2,
      option3,
      option4,
      option5,
      shuffle,
    });
    this.setState({
      questionsArray,
      question: "",
      option1: { value: "", isTrue: false },
      option2: { value: "", isTrue: false },
      option3: { value: "", isTrue: false },
      option4: { value: "", isTrue: false },
      option5: { value: "", isTrue: false },
      shuffle: { question: true, answer: true },
      edit: null,
    });
  }
  renderButtons() {
    return (
      <div>
        <button
          style={{ margin: 5 }}
          onClick={() => {
            this.state.edit !== null
              ? this.editQuestion()
              : this.addMoreQuestion();
          }}
        >
          {this.state.edit !== null
            ? "Edit Question"
            : "Add this question in list"}
        </button>
        <button style={{ margin: 5 }} onClick={() => this.sendDatatoFirebase()}>
          Submit Quiz
        </button>
      </div>
    );
  }

  checkInputs(e, i) {
    const { question, option1, option2, option3, option4, option5 } =
      this.state;

    if (
      question === "" &&
      option1.value === "" &&
      option2.value === "" &&
      option3.value === "" &&
      option4.value === "" &&
      option5.value === ""
    ) {
      this.setState({
        question: e.question,
        option1: e.option1,
        option2: e.option2,
        option3: e.option3,
        option4: e.option4,
        option5: e.option5,
        shuffle: {
          question: e.shuffle.question,
          answer: e.shuffle.answer,
        },
        edit: i,
      });
      window.location.href = "#top";
    } else {
      alert("Please empty the above input fields before editing");
    }
  }

  renderQuestionInputs() {
    return (
      <div style={{ padding: 30 }} className={style.quizForm} id="top">
        <input
          type="text"
          placeholder={`Quiz Title (require)`}
          style={{ width: "40%" }}
          value={this.state.title}
          onChange={(e) => this.setState({ title: e.target.value })}
        />
        <br />
        <br />
        <input
          type="text"
          placeholder={`https://xyz  (optional)`}
          style={{ width: "40%" }}
          value={this.state.link}
          onChange={(e) => this.setState({ link: e.target.value })}
        />
        <div className={style.ageInp}>
          <div>
            <label>Select Language:</label>
            <select
              onChange={(val) => this.setState({ language: val.target.value })}
            >
              <option value="english">English</option>
              <option value="urdu">Urdu</option>
            </select>
          </div>
          <div>
            <label>Minimum Age:</label>
            <input
              type="number"
              placeholder="Age"
              value={this.state.age}
              onChange={(e) => this.setState({ age: e.target.value })}
            />
          </div>
        </div>
        <input
          type="text"
          placeholder={`Question`}
          style={{ width: "50%" }}
          value={this.state.question}
          onChange={(e) => this.setState({ question: e.target.value })}
        />
        <p>Choices</p>
        <input
          type="text"
          placeholder={
            this.state.language === "english"
              ? this.state.english[0]
              : this.state.urdu[0]
          }
          value={this.state.option1.value}
          onChange={(e) =>
            this.setState({
              option1: {
                value: e.target.value,
                isTrue: this.state.option1.isTrue,
              },
            })
          }
        />
        <button
          className="optionBtn"
          onClick={() =>
            this.setState({
              option1: {
                value: this.state.option1.value,
                isTrue: !this.state.option1.isTrue,
              },
            })
          }
        >
          {this.state.option1.isTrue ? "True" : "False"}
        </button>
        <br />
        <input
          type="text"
          placeholder={
            this.state.language === "english"
              ? this.state.english[1]
              : this.state.urdu[1]
          }
          value={this.state.option2.value}
          onChange={(e) =>
            this.setState({
              option2: {
                value: e.target.value,
                isTrue: this.state.option2.isTrue,
              },
            })
          }
        />
        <button
          onClick={() =>
            this.setState({
              option2: {
                value: this.state.option2.value,
                isTrue: !this.state.option2.isTrue,
              },
            })
          }
        >
          {this.state.option2.isTrue ? "True" : "False"}
        </button>
        <br />
        <input
          type="text"
          placeholder={
            this.state.language === "english"
              ? this.state.english[2]
              : this.state.urdu[2]
          }
          value={this.state.option3.value}
          onChange={(e) =>
            this.setState({
              option3: {
                value: e.target.value,
                isTrue: this.state.option3.isTrue,
              },
            })
          }
        />
        <button
          onClick={() =>
            this.setState({
              option3: {
                value: this.state.option3.value,
                isTrue: !this.state.option3.isTrue,
              },
            })
          }
        >
          {this.state.option3.isTrue ? "True" : "False"}
        </button>
        <br />
        <input
          type="text"
          placeholder={
            this.state.language === "english"
              ? this.state.english[3]
              : this.state.urdu[3]
          }
          value={this.state.option4.value}
          onChange={(e) =>
            this.setState({
              option4: {
                value: e.target.value,
                isTrue: this.state.option4.isTrue,
              },
            })
          }
        />
        <button
          onClick={() =>
            this.setState({
              option4: {
                value: this.state.option4.value,
                isTrue: !this.state.option4.isTrue,
              },
            })
          }
        >
          {this.state.option4.isTrue ? "True" : "False"}
        </button>
        <br />
        <input
          type="text"
          placeholder={
            this.state.language === "english"
              ? this.state.english[4]
              : this.state.urdu[4]
          }
          value={this.state.option5.value}
          onChange={(e) =>
            this.setState({
              option5: {
                value: e.target.value,
                isTrue: this.state.option5.isTrue,
              },
            })
          }
        />
        <button
          onClick={() =>
            this.setState({
              option5: {
                value: this.state.option5.value,
                isTrue: !this.state.option5.isTrue,
              },
            })
          }
        >
          {this.state.option5.isTrue ? "True" : "False"}
        </button>
        <div className={style.shuffle}>
          <span>
            <input
              type="checkbox"
              checked={this.state.shuffle.question}
              onChange={(e) =>
                this.setState({
                  shuffle: {
                    ...this.state.shuffle,
                    question: e.target.checked,
                  },
                })
              }
            />
            Shuffle Question
          </span>
          <span>
            <input
              type="checkbox"
              checked={this.state.shuffle.answer}
              onChange={(e) =>
                this.setState({
                  shuffle: { ...this.state.shuffle, answer: e.target.checked },
                })
              }
            />
            Shuffle Answer
          </span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="App">
        {this.state.loader ? <AllExports.Helpers.Loader /> : <></>}
        {this.renderQuestionInputs()}
        {this.renderButtons()}
        {!!this.state.questionsArray.length && (
          <div className={style.quizQuestions}>
            <h3>Questions in Quiz</h3>
            {this.state.questionsArray.map((e, i) => {
              return (
                <div key={i}>
                  <h4>{i + 1 + ")" + e.question}</h4>
                  {e.option1.value !== "" && (
                    <div>
                      {" "}
                      {`${
                        this.state.language === "english"
                          ? this.state.english[0]
                          : this.state.urdu[0]
                      }) ${e.option1.value} ${
                        e.option1.isTrue ? "True" : "False"
                      }`}{" "}
                    </div>
                  )}
                  {e.option2.value !== "" && (
                    <div>
                      {" "}
                      {`${
                        this.state.language === "english"
                          ? this.state.english[1]
                          : this.state.urdu[1]
                      }) ${e.option2.value} ${
                        e.option2.isTrue ? "True" : "False"
                      }`}{" "}
                    </div>
                  )}
                  {e.option3.value !== "" && (
                    <div>
                      {" "}
                      {`${
                        this.state.language === "english"
                          ? this.state.english[2]
                          : this.state.urdu[2]
                      }) ${e.option3.value} ${
                        e.option3.isTrue ? "True" : "False"
                      }`}{" "}
                    </div>
                  )}
                  {e.option4.value !== "" && (
                    <div>
                      {" "}
                      {`${
                        this.state.language === "english"
                          ? this.state.english[3]
                          : this.state.urdu[3]
                      }) ${e.option4.value} ${
                        e.option4.isTrue ? "True" : "False"
                      }`}{" "}
                    </div>
                  )}
                  {e.option5.value !== "" && (
                    <div>
                      {" "}
                      {`${
                        this.state.language === "english"
                          ? this.state.english[4]
                          : this.state.urdu[4]
                      }) ${e.option5.value} ${
                        e.option5.isTrue ? "True" : "False"
                      }`}{" "}
                    </div>
                  )}
                  <br />
                  Shuffle Question: {e.shuffle.question.toString()}
                  &nbsp; Shuffle Answer: {e.shuffle.answer.toString()}
                  <br />
                  <button onClick={() => this.checkInputs(e, i)}>
                    Edit Question
                  </button>
                  &nbsp;
                  <button onClick={() => this.deletFromArray(i)}>
                    Delete Question
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
export default QuizesAdd;
