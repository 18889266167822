import React from "react";
import { loader } from "../Assets";

export default function Loader() {
  return (
    <div className="Loader">
      <img src={loader} alt="loader" width={80} height={80} />
    </div>
  );
}
