import React, { useEffect, useState } from "react";
import style from "./users.module.css";
import { database } from "../../../Config/firebase";
import { ref, update } from "firebase/database";
import { Alert } from "rsuite";
import {
  convertEpochToDate,
  isNotNullOrEmpty,
} from "../../../Functions/inAppFunctions";

const countObj = {
  all: 0,
  deathNews: 0,
  deathAnniversaries: 0,
  events: 0,
  testing: 0,
};

const SubscribedDevices = ({ FCMTopics }) => {
  const [topicsCount, setTopicsCount] = useState(countObj);
  const [deviceInfo, setDeviceInfo] = useState([]);

  const countSubscriptions = (subscriptions, count) => {
    for (const key in count) {
      if (subscriptions[key]) {
        ++count[key];
      }
    }
  };

  const getDeviceInfo = () => {
    let arr = [];
    let count = {
      all: 0,
      deathNews: 0,
      deathAnniversaries: 0,
      events: 0,
      testing: 0,
    };

    for (const key in FCMTopics) {
      const subscriptions = FCMTopics[key];
      countSubscriptions(subscriptions, count);
      if (subscriptions.deviceInfo) {
        arr.push(subscriptions);
      }
    }

    setTopicsCount(count);
    setDeviceInfo(arr);
  };

  useEffect(() => {
    getDeviceInfo();
  }, [FCMTopics]);

  const allowTesting = (topicKey, permission) => {
    update(ref(database, `topicSubscriptions/${topicKey}/deviceInfo`), {
      allowTesting: permission,
    })
      .then(() => {
        Alert.success(
          `Testing ${permission ? "Enabled" : "Disabled"} Successfully`
        );
      })
      .catch((error) => {
        Alert.error("An Error Occurred. Check Logs");
        console.log("Updating Error", error);
      });
  };

  return (
    <main className={style.main}>
      <h3>Topic Details</h3>
      <ol>
        <li>All: {topicsCount.all}</li>
        <li>Death News: {topicsCount.deathNews}</li>
        <li>Events: {topicsCount.events}</li>
        <li>Death Anniversaries: {topicsCount.deathAnniversaries}</li>
        <li>Testing: {topicsCount.testing}</li>
        <li>Devices: {deviceInfo.length}</li>
      </ol>
      <h3>Subscribed Devices</h3>
      <table className="dataTable">
        <thead>
          <tr>
            <th>S.NO</th>
            <th>Key</th>
            <th>Brand</th>
            <th>Model</th>
            <th>Android Version</th>
            <th>DateTime</th>
            <th>All</th>
            <th>Death News</th>
            <th>Events</th>
            <th>Anniversaries</th>
            <th>Testing</th>
            <th>Allow Testing</th>
          </tr>
        </thead>
        <tbody>
          {deviceInfo.map((ele, ind) => {
            const device = ele.deviceInfo;
            return (
              <tr key={ind}>
                <td>{ind + 1}</td>
                <td>{device.key}</td>
                <td>{device.brand}</td>
                <td>{device.model}</td>
                <td>{device.androidVersion}</td>
                <td>
                  {isNotNullOrEmpty(device.timestamp) &&
                    convertEpochToDate(device.timestamp, 3)}
                </td>
                <td>{ele.all ? ele.all.toString() : "-"}</td>
                <td>{ele.deathNews ? ele.deathNews.toString() : "-"}</td>
                <td>{ele.events ? ele.events.toString() : "-"}</td>
                <td>
                  {ele.deathAnniversaries
                    ? ele.deathAnniversaries.toString()
                    : "-"}
                </td>
                <td>{ele.testing ? ele.testing.toString() : "-"}</td>
                <td>
                  <button
                    onClick={() =>
                      allowTesting(device.key, !device.allowTesting)
                    }
                  >
                    {device.allowTesting ? "Disable" : "Enable"}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </main>
  );
};

export default SubscribedDevices;
