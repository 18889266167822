let html = `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
<head><!-- NAME: ANNOUNCE --><!--[if gte mso 15]>
        <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
        <![endif]--><meta charset="UTF-8"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="viewport" content="width=device-width, initial-scale=1">
	<title></title>
	<style type="text/css">p{
			margin:10px 0;
			padding:0;
		}
		table{
			border-collapse:collapse;
		}
		h1,h2,h3,h4,h5,h6{
			display:block;
			margin:0;
			padding:0;
		}
		img,a img{
			border:0;
			height:auto;
			outline:none;
			text-decoration:none;
		}
		body,#bodyTable,#bodyCell{
			height:100%;
			margin:0;
			padding:0;
			width:100%;
		}
		.mcnPreviewText{
			display:none !important;
		}
		#outlook a{
			padding:0;
		}
		img{
			-ms-interpolation-mode:bicubic;
		}
		table{
			mso-table-lspace:0pt;
			mso-table-rspace:0pt;
		}
		.ReadMsgBody{
			width:100%;
		}
		.ExternalClass{
			width:100%;
		}
		p,a,li,td,blockquote{
			mso-line-height-rule:exactly;
		}
		a[href^=tel],a[href^=sms]{
			color:inherit;
			cursor:default;
			text-decoration:none;
		}
		p,a,li,td,body,table,blockquote{
			-ms-text-size-adjust:100%;
			-webkit-text-size-adjust:100%;
		}
		.ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
			line-height:100%;
		}
		a[x-apple-data-detectors]{
			color:inherit !important;
			text-decoration:none !important;
			font-size:inherit !important;
			font-family:inherit !important;
			font-weight:inherit !important;
			line-height:inherit !important;
		}
		.templateContainer{
			max-width:600px !important;
		}
		a.mcnButton{
			display:block;
		}
		.mcnImage,.mcnRetinaImage{
			vertical-align:bottom;
		}
		.mcnTextContent{
			word-break:break-word;
		}
		.mcnTextContent img{
			height:auto !important;
		}
		.mcnDividerBlock{
			table-layout:fixed !important;
		}
		h1{
			color:#222222;
			font-family:Helvetica;
			font-size:40px;
			font-style:normal;
			font-weight:bold;
			line-height:125%;
			letter-spacing:normal;
			text-align:center;
		}
		h2{
			color:#222222;
			font-family:Helvetica;
			font-size:34px;
			font-style:normal;
			font-weight:bold;
			line-height:150%;
			letter-spacing:normal;
			text-align:left;
		}
		h3{
			color:#444444;
			font-family:Helvetica;
			font-size:22px;
			font-style:normal;
			font-weight:bold;
			line-height:150%;
			letter-spacing:normal;
			text-align:left;
		}
		h4{
			color:#949494;
			font-family:Georgia;
			font-size:20px;
			font-style:italic;
			font-weight:normal;
			line-height:125%;
			letter-spacing:normal;
			text-align:center;
		}
		#templateHeader{
			background-color:#ffffff;
			background-image:none;
			background-repeat:no-repeat;
			background-position:50% 50%;
			background-size:cover;
			border-top:0;
			border-bottom:0;
			padding-top:16px;
			padding-bottom:16px;
		}
		.headerContainer{
			background-color:#transparent;
			background-image:none;
			background-repeat:no-repeat;
			background-position:center;
			background-size:cover;
			border-top:0;
			border-bottom:0;
			padding-top:0;
			padding-bottom:0;
		}
		.headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
			color:#757575;
			font-family:Helvetica;
			font-size:16px;
			line-height:150%;
			text-align:left;
		}
		.headerContainer .mcnTextContent a,.headerContainer .mcnTextContent p a{
			color:#007C89;
			font-weight:normal;
			text-decoration:underline;
		}
		#templateBody{
			background-color:#ffffff;
			background-image:none;
			background-repeat:no-repeat;
			background-position:center;
			background-size:cover;
			border-top:0;
			border-bottom:0;
			padding-top:0px;
			padding-bottom:0px;
		}
		.bodyContainer{
			background-color:#transparent;
			background-image:none;
			background-repeat:no-repeat;
			background-position:center;
			background-size:cover;
			border-top:0;
			border-bottom:0;
			padding-top:0;
			padding-bottom:0;
		}
		.bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
			color:#757575;
			font-family:Helvetica;
			font-size:16px;
			line-height:150%;
			text-align:left;
		}
		.bodyContainer .mcnTextContent a,.bodyContainer .mcnTextContent p a{
			color:#007C89;
			font-weight:normal;
			text-decoration:underline;
		}
		#templateFooter{
			background-color:#521919;
			background-image:none;
			background-repeat:no-repeat;
			background-position:center;
			background-size:cover;
			border-top:0;
			border-bottom:0;
			padding-top:0px;
			padding-bottom:0px;
		}
		.footerContainer{
			background-color:transparent;
			background-image:none;
			background-repeat:no-repeat;
			background-position:center;
			background-size:cover;
			border-top:0;
			border-bottom:0;
			padding-top:0;
			padding-bottom:0;
		}
		.footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
			color:#FFFFFF;
			font-family:Helvetica;
			font-size:12px;
			line-height:150%;
			text-align:center;
		}
		.footerContainer .mcnTextContent a,.footerContainer .mcnTextContent p a{
			color:#FFFFFF;
			font-weight:normal;
			text-decoration:underline;
		}
	@media only screen and (min-width:768px){
		.templateContainer{
			width:600px !important;
		}

}	@media only screen and (max-width: 480px){
		body,table,td,p,a,li,blockquote{
			-webkit-text-size-adjust:none !important;
		}

}	@media only screen and (max-width: 480px){
		body{
			width:100% !important;
			min-width:100% !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnRetinaImage{
			max-width:100% !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnImage{
			width:100% !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
			max-width:100% !important;
			width:100% !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnBoxedTextContentContainer{
			min-width:100% !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnImageGroupContent{
			padding:9px !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
			padding-top:9px !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
			padding-top:18px !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnImageCardBottomImageContent{
			padding-bottom:9px !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnImageGroupBlockInner{
			padding-top:0 !important;
			padding-bottom:0 !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnImageGroupBlockOuter{
			padding-top:9px !important;
			padding-bottom:9px !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnTextContent,.mcnBoxedTextContentColumn{
			padding-right:18px !important;
			padding-left:18px !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
			padding-right:18px !important;
			padding-bottom:0 !important;
			padding-left:18px !important;
		}

}	@media only screen and (max-width: 480px){
		.mcpreview-image-uploader{
			display:none !important;
			width:100% !important;
		}

}	@media only screen and (max-width: 480px){
		h1{
			font-size:30px !important;
			line-height:125% !important;
		}

}	@media only screen and (max-width: 480px){
		h2{
			font-size:26px !important;
			line-height:125% !important;
		}

}	@media only screen and (max-width: 480px){
		h3{
			font-size:20px !important;
			line-height:150% !important;
		}

}	@media only screen and (max-width: 480px){
		h4{
			font-size:18px !important;
			line-height:150% !important;
		}

}	@media only screen and (max-width: 480px){
		.mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
			font-size:14px !important;
			line-height:150% !important;
		}

}	@media only screen and (max-width: 480px){
		.headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
			font-size:16px !important;
			line-height:150% !important;
		}

}	@media only screen and (max-width: 480px){
		.bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
			font-size:16px !important;
			line-height:150% !important;
		}

}	@media only screen and (max-width: 480px){
		.footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
			font-size:14px !important;
			line-height:150% !important;
		}

}
	</style>
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css?family=Arvo:400,400i,700,700i|Playfair+Display:400,400i,700,700i" rel="stylesheet" /><!--<![endif]-->
</head>
<body data-gr-ext-installed="" data-new-gr-c-s-check-loaded="14.1047.0" data-new-gr-c-s-loaded="14.1047.0" style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
<center>
<table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" width="100%">
	<tbody>
		<tr>
			<td align="center" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;" valign="top"><!-- BEGIN TEMPLATE // -->
			<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
				<tbody>
					<tr>
						<td align="center" data-template-container="" id="templateHeader" style="background:#ffffff none no-repeat 50% 50%/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: 50% 50%;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 16px;padding-bottom: 16px;" valign="top"><!--[if (gte mso 9)|(IE)]>
                                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                                    <tr>
                                    <td align="center" valign="top" width="600" style="width:600px;">
                                    <![endif]-->
						<table align="center" border="0" cellpadding="0" cellspacing="0" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;max-width: 600px !important;" width="100%">
							<tbody>
								<tr>
									<td class="headerContainer" style="background:#transparent none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #transparent;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0;padding-bottom: 0;" valign="top"><img alt="" src="https://email.kpsiaj.org/uploads/1638448750.jpg" style="width: 605px; height: 154px;" />
									<table border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
										<tbody class="mcnImageCardBlockOuter">
											<tr>
												<td class="mcnImageCardBlockInner" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top">
												<table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" style="background-color: #E8E8E8;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
													<tbody>
														<tr>
															<td align="center" class="mcnImageCardBottomImageContent" style="padding-top: 18px;padding-right: 18px;padding-bottom: 0;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top"><img alt="" class="mcnImage" src="https://gallery.mailchimp.com/7bbdbf1c810ba72c6ee29133e/images/39a45164-3be9-4b80-a126-b530248294f3.jpg" style="max-width: 310px;border-radius: 1%;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" width="310" /></td>
														</tr>
														<tr>
															<td class="mcnTextContent" style="padding: 9px 18px;color: #FFFFFF;font-family: Helvetica;font-size: 14px;font-weight: normal;line-height: 150%;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;" valign="top" width="528">
															<div dir="rtl" style="text-align: center;"><strong><span style="font-family:arvo,courier,georgia,serif"><span style="font-size:17px"><span style="color:#000000"><span font-size:24px="">ہم اللہ کے لئے ہیں اور اسی کی بارگاہ میں واپس جانے والے ہیں</span></span></span><span style="font-size:18px"><span style="color:#000000"><span font-size:24px="">&nbsp;&nbsp;</span></span></span></span></strong></div>

															<div style="text-align: center;"><span style="font-family:playfair display,georgia,times new roman,serif"><span style="font-size:17px"><span style="color:#000000"><span font-size:24px="">To Allah do we belong, and to Him do we return<br />
															(Surah e Baqrah -156)</span></span></span></span></div>
															</td>
														</tr>
													</tbody>
												</table>
												</td>
											</tr>
										</tbody>
									</table>

									<table border="0" cellpadding="0" cellspacing="0" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
										<tbody class="mcnTextBlockOuter">
											<tr>
												<td class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top"><!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]--><!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
												<table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnTextContentContainer" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
													<tbody>
														<tr>
															<td class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;" valign="top">
															<div style="text-align: center;"><span style="color:#000000"><span style="font-family:playfair display,georgia,times new roman,serif"><span style="font-size:16px">With heartfelt condolence, we inform you that<br />
															our following member has passed away:</span></span></span></div>
															</td>
														</tr>
													</tbody>
												</table>
												<!--[if mso]>
				</td>
				<![endif]--><!--[if mso]>
				</tr>
				</table>
				<![endif]--></td>
											</tr>
										</tbody>
									</table>

									<table border="0" cellpadding="0" cellspacing="0" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
										<tbody class="mcnTextBlockOuter">
											<tr>
												<td class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" valign="top"><!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]--><!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
												<table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnTextContentContainer" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%">
													<tbody>
														<tr>
															<td class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #757575;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;" valign="top">
															<div style="text-align: center;"><span style="color:#000000"><span style="font-family:playfair display,georgia,times new roman,serif"><span style="font-size:18px"><strong>Maher Bano&nbsp;(84 years)</strong></span><br />
															<span style="font-size:15px"><em>helloworld</em></span><br />
															<div>burialimage<div>
															<br />
															Tadfeen (Burial) to be&nbsp;held at <strong>Wadi e Zainab.</strong><br />
															<br />
															<span style="font-size:16px">Namaz-e-Janaza to be&nbsp;offered on Tuesday<u>, 14-Feb-2023</u>&nbsp;</span></span></span></div>

															<div style="text-align: center;"><span style="color:#000000"><span style="font-family:playfair display,georgia,times new roman,serif"><span style="font-size:16px"><strong>after Namaz e &nbsp;Zohrain at Mehfil-e-Shah-e-Khurasan</strong></span></span></span></div>

															<div style="text-align: center;">&nbsp;</div>

															<div style="text-align: center;"><span style="color:#000000"><span style="font-family:playfair display,georgia,times new roman,serif"><span style="font-size:16px">It is &nbsp;recommended to give alms (sadqa) for the deceased and offer Fateha &amp;<br />
															Namaz-e-Wahshat-e-Qabr. May Allah (subhanu wata&#39;ala) grant deceased</span></span></span></div>

															<div style="text-align: center;"><span style="color:#000000"><span style="font-family:playfair display,georgia,times new roman,serif"><span style="font-size:16px">maghfirat &amp; vicinity of Ahlulbayt (alaihis salaam) in Jannah. Ameen.</span></span></span></div>
															</td>
														</tr>
													</tbody>
												</table>
												<!--[if mso]>
				</td>
				<![endif]--><!--[if mso]>
				</tr>
				</table>
				<![endif]--></td>
											</tr>
										</tbody>
									</table>
									</td>
								</tr>
							</tbody>
						</table>
						<!--[if (gte mso 9)|(IE)]>
                                    </td>
                                    </tr>
                                    </table>
                                    <![endif]--></td>
					</tr>
					<tr>
						<td align="center" data-template-container="" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 0px;padding-bottom: 0px;" valign="top">
						<center>
						<table align="center" height="100%" id="bodyTable" style="border-collapse:collapse; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%; height:100%; padding:0; width:100%" width="100%">
							<tbody>
								<tr>
									<td align="center" id="bodyCell" style="height:100%; padding:0; width:100%" valign="top">
									<table style="border-collapse:collapse; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%" width="100%">
										<tbody>
											<tr>
												<td align="center" data-template-container="" id="templateFooter" style="border-bottom:0; background-color:#511918; background-image:none; background-repeat:no-repeat; background-position:center; background-size:cover; padding-top:0px; padding-bottom:0px; border-top:0" valign="top">
												<table align="center" class="templateContainer" style="border-collapse:collapse; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%; max-width:600px !important" width="100%">
													<tbody>
														<tr>
															<td class="footerContainer" style="border-bottom:0; background-image:none; background-repeat:no-repeat; background-position:center; background-size:cover; padding-top:0; padding-bottom:0; border-top:0" valign="top">
															<table class="mcnTextBlock" style="min-width:100%; border-collapse:collapse; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%" width="100%">
																<tbody class="mcnTextBlockOuter">
																	<tr>
																		<td class="mcnTextBlockInner" style="padding-top:9px" valign="top">
																		<table align="left" class="mcnTextContentContainer" style="max-width:100%; min-width:100%; border-collapse:collapse; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%" width="100%">
																			<tbody>
																				<tr>
																					<td class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px; line-height:150%; text-align:center" valign="top">
																					<center>
																					<table style="border-collapse:collapse; -ms-text-size-adjust:100%; -webkit-text-size-adjust:100%">
																						<tbody>
																							<tr>
																								<td style="padding-top: 5px;line-height: 10px;font-size: 6px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://api.whatsapp.com/send?phone=923343913770" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;"><img align="absbottom" alt="WhatSapp" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/WhatsApp.png" style="border: 0px initial;display: block;height: auto;outline: none;text-decoration: none;margin: 0px;-ms-interpolation-mode: bicubic;" width="25px" /> </a></td>
																								<td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.faceboo.com/kpsiajkarachi" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;" target="_blank"><img align="absbottom" alt="Facebook" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Fb.png" style="display: block;height: auto;outline: none;text-decoration: none;flot: left;border: 0;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
																								<td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.twitter.com/kpsiajkarachi" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;"><img align="absbottom" alt="Twitter" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Twitter.png" style="border: 0px;display: block;height: auto;outline: none;text-decoration: none;margin: 0px;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
																								<td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.youtube.com/channel/UCdft3Z35RG2U2VoJo7HHXIw" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;" target="_blank"><img align="absbottom" alt="Youtube" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Youtube.png" style="display: block;height: auto;outline: none;text-decoration: none;flot: left;border: 0;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
																								<td style="padding-left: 10px;padding-top: 5px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"><a href="https://www.kpsiaj.org" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #FFFFFF;font-weight: normal;text-decoration: underline;" target="_blank"><img align="absbottom" alt="website" height="25px" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Web.png" style="display: block;height: auto;outline: none;text-decoration: none;flot: left;border: 0;-ms-interpolation-mode: bicubic;" width="25px" /></a></td>
																							</tr>
																						</tbody>
																					</table>

																					<div><br />
																					<span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="font-size:12px"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="word-break:break-word"><span style="color:#ffffff"><span style="font-family:Helvetica"><a href="https://play.google.com/store/apps/details?id=com.kpsiaj" style="-ms-text-size-adjust:100%; -webkit-text-size-adjust:100%; color:#ffffff; font-weight:normal; text-decoration:underline" target="_blank"><img align="absbottom" alt="Android App" class="mcnImage" src="http://www.kpsiaj.org/kpsiaj/images/misc/transparenticons/Android.png" style="display:block; height:auto; outline:none; text-decoration:none; flot:left; width:80px; border:0; -ms-interpolation-mode:bicubic; vertical-align:bottom" /></a></span></span></span></span></span></span></span></span></span></span></span></span></span></span></div>

																					<hr /><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="font-size:12px"><span style="-ms-text-size-adjust:100%"><span style="-webkit-text-size-adjust:100%"><span style="word-break:break-word"><span style="color:#ffffff"><span style="font-family:Helvetica"><span class="mcnTextContent" style="font-size:12px; text-align:center"><span style="color:white"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="word-break:break-word"><span style="line-height:150%">Media &amp; Outreach Department, Khoja (Pirhai) Shia Isna Asheri Jamaat, Karachi<br />
																					<br />
																					If you don&#39;t wish to receive email updates from KPSIAJ, you may unsubscribe via this link&nbsp;</span></span></span></span></span>[unsubscribe]</span></span></span></span></span></span></span></span></span></span></span></span></span></span></center>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																		</td>
																	</tr>
																</tbody>
															</table>
															</td>
														</tr>
													</tbody>
												</table>
												</td>
											</tr>
										</tbody>
									</table>
									</td>
								</tr>
							</tbody>
						</table>
						</center>
						</td>
					</tr>
				</tbody>
			</table>
			</td>
		</tr>
	</tbody>
</table>
<style type="text/css">@media only screen and (max-width: 480px){
                        table#canspamBar td{font-size:14px !important;}
                        table#canspamBar td a{display:block !important; margin-top:10px !important;}
                    }
</style>
</center>
</body>
</html>
`;
export default html;
